<template>
  <div class="float-wrap">
    <div @click="handleBackTop" class="back-top flex" :class="{ 'back-top-show': backTopShow }">
      <svg t="1713495564813" class="arrow-up" viewBox="0 0 1812 1024" p-id="2003">
        <path
              d="M889.848778 273.683698 194.723176 968.8093C150.261273 1013.271282 78.068836 1013.264037 33.537475 968.732676-11.304397 923.890804-11.028221 852.035969 33.460773 807.546975L807.673526 33.334221C852.13543-11.127682 924.327866-11.120516 968.859227 33.410924 970.936651 35.488347 972.917291 37.623731 974.801147 39.811798 981.564586 44.15155 987.967823 49.277935 993.880605 55.190796L1768.093358 829.403471C1812.582352 873.892543 1812.858528 945.747379 1768.016656 990.589172 1723.485295 1035.120612 1651.292858 1035.127778 1606.830955 990.665875L889.848778 273.683698Z">
        </path>
      </svg>
    </div>
  </div>
</template>
<script>

export default {
  name:'FloatButton',
  data() {
    return {
      backTopShow: false
    }
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.backTopShow = window.scrollY > 1000 ? true : false;
    })
  },
  methods: {
    handleBackTop() {
      window.scrollTo(0, 0);
    }
  },
}
</script>
<style lang="scss" scoped>
.float-wrap {
  position: fixed;
  right: 30px;
  bottom: 95px;
  z-index: 1;
  .back-top {
    cursor: pointer;
    margin-top: 20px;
    margin-left: 100%;
    width: 70px;
    height: 48px;
    opacity: 0;
    z-index: -10;
    border-radius: 24px;
    background-color: rgba(87, 92, 102, .4);
    transition: all .3s ease;
    .arrow-up {
      width: 30px;
      fill: #fff;
    }
  }

  .back-top-show {
    margin-left: 0;
    opacity: 1;
    z-index: 1;
  }
}

@media(max-width:768px) {
  .float-wrap {
    position: fixed;
    right: 20px;
    bottom: 95px;

    .back-top {
      cursor: pointer;
      margin-top: 20px;
      margin-left: 100%;
      width: 45px;
      height: 30px;
      opacity: 0;
      z-index: -10;
      border-radius: 24px;
      background-color: rgba(87, 92, 102, .4);
      transition: all .3s ease;
      .arrow-up {
        width: 20px;
      }
    }

    .back-top-show {
      margin-left: 0;
      opacity: 1;
      z-index: 1;
    }
  }
}
</style>