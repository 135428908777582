import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './locales'
import Vant from 'vant';
import APIURL from './api/APIURL'
import http from './api/http'
import '@vant/touch-emulator';
import 'vant/lib/index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/styles/global.css';/*引入公共样式*/
import '../src/styles/iconfont/iconfont.css';
import { Lazyload } from 'vant';
import Vconsole from "vconsole";
import { Modal,FormModel,Input,Row,Col,Icon,Button,Table,Space,Spin,Select,Empty,Divider,Pagination,Breadcrumb } from 'ant-design-vue'
//开发环境使用，生产环境自动取消
if (process.env.NODE_ENV == "development") {
    new Vconsole();
}
Vue.use(Modal)
Vue.use(Table)
Vue.use(Select);
Vue.use(Input)
Vue.use(FormModel);
Vue.use(Breadcrumb);
Vue.component(Button.name, Button);
Vue.component(Space.name, Space);
Vue.component(Spin.name, Spin);
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.component(Icon.name, Icon);
Vue.component(Empty.name, Empty);
Vue.component(Divider.name, Divider);
Vue.component(Pagination.name, Pagination);

Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.config.productionTip = false
Vue.prototype.APIURL = APIURL;
Vue.prototype.$http = http;
Vue.use(Vant);

function isAPP(){
	const system = navigator.userAgent.toLowerCase();
	if (system.includes('triproaming') && system.includes('ios')) {
			return 'ios';
	} else if (system.includes('triproaming') && system.includes('android')) {
			return 'android';
	} else if(system.includes('miniprogram')){
		return 'wx';
	} else {
		return false
	}
}
(function() {
  const system = isAPP();
  store.commit('SET_IS_APP', system,{root:true});
})();
router.afterEach((to, from) => {
	const { meta:{title_en,title_cn} } = to
	document.title = i18n.locale == 'en' ? title_en : title_cn
})
router.beforeEach((to, from, next) => {
	Modal.destroyAll();
	const { name,meta:{requiresAuth},query:{lang,token,authorization,iccid} } = to
	if(lang) {
		i18n.locale = to.query.lang == 'en' ? 'en' : 'zh-cn'
		localStorage.setItem('sim_lang', i18n.locale)
	}
	if(store.state['isApp']&&!requiresAuth) return next();

	if (token || authorization) {
		const authToken = authorization || token;
		store.dispatch('user/AuthTokenLogin', decodeURIComponent(authToken))
	}

	if (requiresAuth && !store.getters['user/isLogin']) {
		if (store.state['isApp']) return next();
		store.dispatch('user/Reset',true)
		const redirect = { name,query:to.query}
		store.commit('SET_REDIRECT',redirect,{root:true})
		if(from.name!=='Home') next('/');
	} else {
		if( name === 'Topup'&&!iccid) return next('/mycards'); 
		next();
	}
})

const app = new Vue({
    i18n,
    store,
    router,
    render: h => h(App)
}).$mount('#app')

export default app
