import axios from 'axios'
import store from '../store'
import router from '../router';
import { Toast } from 'vant';
import { message } from 'ant-design-vue';

const service = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 60000
})
// 异常拦截处理器
const errorHandler = (error) => {
  Toast.clear();
  if (error.request.status !== 200) {
    message.error(error.message)
  }
  return Promise.reject(error)
}
service.interceptors.request.use(config => {

   config.headers = {
     'content-Type':'application/json' //配置请求头
   }
   const token = store.state.user.token || '';
   if(token){
      config.headers.Authorization= token;
  }
  return config
}, errorHandler);

service.interceptors.response.use(response => {
  Toast.clear();
  const {code,msg} = response.data;
  if (code !== 1) {
    //401 token不对 402 其他终端登录
    const resultCode = [401,402]
    if(resultCode.includes(code)){
      store.dispatch('user/Reset',true);
      const currentRoute = router.currentRoute;
      const { name, query , meta:{requiresAuth} } = currentRoute;
      if(requiresAuth)store.commit('SET_REDIRECT',{ name, query },{root:true})
    }
    message.warning(msg)
    return Promise.reject(response.data)
  }
  return response.data;
}, errorHandler);

export default service
