<template>
  <div class="home">
    <a-spin :spinning="isLoading" size="large">
      <a-carousel ref="carousel" autoplay effect="fade">
        <div class="banner-warp" >
          <!-- <a-icon type="left" class="prev" @click="prevHandler"/> -->
          <img class="banner-img" v-if="$i18n.locale == 'zh-cn'" src="../assets/wyb_banner_zh-cn.png" alt="">
          <img class="banner-img" v-else src="../assets/wyb_banner_en.png" alt="">
          <!-- <a-icon type="right" class="next" @click="nextHandler"/> -->
        </div>
      </a-carousel>
      <div class="coverage-area">
        <div class="container">
          <div class="section-title">{{ $t("common.area") }}</div>
          <div class="area-img flex">
            <img :src="require(`../assets/esim_area_${$i18n.locale}.png`)" alt="" />
          </div>
          <div class="flex">
            <button class="leave-for flex" @click="onLeaveFor">
              <span>{{$t('common.area_global')}}</span>
              <svg viewBox="0 0 13 10" height="10px" width="15px">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <reviews-bar v-if="reviewsLoad"/>
      <div class="section-title">
        {{$t('common.all')}}
      </div>
      <div class="container section-products" v-cloak>
        <van-tabs
          ref="tabs"
          v-model="active"
          animated
          swipeable
          sticky
          color="#2564F6"
          @scroll="scrollCallback"
          :offset-top="navBarHeight"
        >
          <van-tab :title="area.continentname" v-for="(area, i) in list" :key="i">
            <a-row :gutter="[{ xs: 14, sm: 20, xxl: 30},{ xs: 14, sm: 20, xxl: 30}]" class="products">
              <a-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6" v-for="item in area.distictSub" :key="item.district_id">
              <div class="product-item" @click="handleBuyNow(item)" :title="item.district_name">
                <div class="product-cover">
                  <img class="product-img" :src="item.cover" :alt="item.district_name" :title="item.district_name"/>
                </div>
                <div class="product-info">
                  <div class="product-name text-overflow-example">{{ item.district_name }}</div>
                  <div class="product-lowest-price">
                    <span class="product-price-from">{{ $t("common.from") }}</span>
                    <span>{{ item.lowest_price }}</span>
                  </div>
                  <div class="product-icon flex">
                    <a-icon type="right" />
                  </div>
                </div>
              </div>
              </a-col>
            </a-row>
          </van-tab>
        </van-tabs>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { mapState,mapGetters } from "vuex";
import { debounce } from "../utils/dbucTrtl"
import { Carousel } from 'ant-design-vue';
import ReviewsBar from '../components/ReviewsBar.vue';
export default {
  name:'Home',
  components:{
    ReviewsBar,
    [Carousel.name]:Carousel,
  },
  name: "Home",
  data() {
    return {
      active: 0,
      isFixed:false,
      isLoading:false,
      reviewsLoad:false,//页面后退重载Swiper
      list:[]
    };
  },
  activated() {
    this.reviewsLoad = true
    this.debouncedResizeHandler = debounce(this.handleTabsResize, 1000);
    window.addEventListener("resize", this.debouncedResizeHandler);
  },
  deactivated() {
    this.reviewsLoad = false
    window.removeEventListener("resize", this.debouncedResizeHandler);
  },
  watch:{
    isFixed() {
      this.debouncedResizeHandler()
    },
    isLogin(){
      this.init();
    },
  },
  computed: {
    ...mapGetters('user', ['isLogin']),
    ...mapState(["navBarHeight"]),
  },
  mounted() {
    this.init()
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.debouncedResizeHandler);
  },
  methods: {
    async init(){
      this.isLoading = true
      const { data:{continent}} = await this.$http.get(this.APIURL.HOME.INDEX,{},false)
      this.list = continent.filter(item=>item.distictSub.length>0)
      this.isLoading = false
    },
    scrollCallback({isFixed}){
      this.isFixed = isFixed
    },
    async handleTabsResize() {
      console.log("resize");
      this.$refs.tabs.resize();
    },
    onLeaveFor(){
      window.open(`https://www.triproaming.com/mapbox/travelmate/?lang=${this.$i18n.locale}`)
    },
    prevHandler(){
      this.$refs.carousel.prev()
    },
    nextHandler(){
      this.$refs.carousel.next()
    },
    handleBuyNow(item) {
      const { district_id:id,district_name:name } = item
      const query = { id }
      this.$router.push({ name: 'Details', query });
      window._hmt.push(['_trackEvent', 'button', 'click', `浏览套餐-${name}`]);
    },
  },
};
</script>
<style lang="scss" scoped>
.banner-warp{
  position: relative;
  height: 100%;
  .banner-img{
    user-select: none;
    cursor: pointer;
    width: 100%;
    height: auto;
  }
  .prev{
    font-size: 40px;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    transition: color .6s ease;
    &:hover{
      color: #fff;
    }
  }
  .next{
    font-size: 40px;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: color .6s ease;
    &:hover{
      color: #fff;
    }
  }
}
.coverage-area{
  padding-top: 60px;
  padding-bottom: 80px;
  .area-img{
    margin-bottom: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .leave-for{
    padding: 10px 18px;
    border: none;
    font-size: 16px;
    color: #fff;
    letter-spacing: 0.05em;
    border-radius: 24px;
    transition: all 0.2s ease;
    background: linear-gradient(to bottom right, #27D3E9 20%, #6B85FF 80%);
    svg {
      margin-left: 10px;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: #fff;
      stroke-width: 2;
      transform: translateX(-5px);
      transition: all 0.3s ease;
    }
    &:hover{
      svg {
        transform: translateX(0);
      }
    }
    &:active {
      transform: scale(0.95);
    }
  }
}

@media(max-width:768px){
  .banner-warp{
    .banner-img{
      width: 100%;
      height: 22vh;
      object-fit: cover;
    }
    .prev{
      font-size: 30px;
    }
    .next{
      font-size: 30px;
    }
  }
  .coverage-area{
    padding-top: 20px;
    padding-bottom: 20px;
    .leave-for{
      padding: 6px 16px;
      font-size: 14px;
    }
  }
}
</style>
