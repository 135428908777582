import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title_cn: '网卡',
      title_en: 'SIM',
    }
  },
  {
    path: '/mycards',
    name: 'MyCards',
    meta: {
      requiresAuth: true,
      title_cn: '我的卡',
      title_en: 'My Card(s)',
    },
    component: () => import('../views/MyCards.vue')
  },
  {
    path: '/topup',
    name: 'Topup',
    meta: {
      requiresAuth: true,
      title_cn: '充值套餐',
      title_en: 'Top-up',
    },
    component: () => import('../views/Topup.vue')
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      title_cn: '搜索',
      title_en: 'Search',
    },
    component: () => import('../views/Search.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Mine.vue'),
    children: [
      {
        path: '/mine/user',
        name: 'User',
        meta: {
          requiresAuth: true,
          title_cn: '个人信息',
          title_en: 'Account',
        },
        component: () => import('../views/User.vue'),
      },
      {
        path: '/mine/simorder',
        name: 'SimOrder',
        meta: {
          requiresAuth: true,
          title_cn: '购卡订单',
          title_en: 'Sim card orders',
        },
        component: () => import('../views/SimOrder.vue'),
      },
      {
        path: '/mine/floworder',
        name: 'FlowOrder',
        meta: {
          requiresAuth: true,
          title_cn: '充值订单',
          title_en: 'Top-up orders',
        },
        component: () => import('../views/FlowOrder.vue'),
      },
      {
        path: '/mine/guide',
        name: 'Guide',
        meta: {
          requiresAuth: true,
          title_cn: '使用帮助',
          title_en: 'User Manual',
        },
        component: () => import('../views/Guide.vue'),
      },
      {
        path: '/mine/address',
        name: 'Address',
        meta: {
          requiresAuth: true,
          title_cn: '收货地址',
          title_en: 'Address',
        },
        component: () => import('../views/Address.vue'),
      },
    ]
  },
  {
    path: '/invite',
    name: 'Invite',
    meta: {
      requiresAuth: true,
      title_cn: '邀请有礼',
      title_en: 'Gift for Invitation',
    },
    component: () => import('../views/Invite.vue'),
  },
  {
    path: '/activityrules',
    name: 'ActivityRules',
    meta: {
      title_cn: '活动规则',
      title_en: 'Activity Rules',
    },
    component: () => import('../views/ActivityRules.vue'),
  },
  {
    path: '/details',
    name: 'Details',
    meta: {
      title_cn: '商品详情',
      title_en: 'Product details',
    },
    component: () => import('../views/Details.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    meta: {
      requiresAuth: true,
      title_cn: '确认订单',
      title_en: 'Confirm',
    },
    component: () => import('../views/Pay.vue')
  },
  {
    path: '/result',
    name: 'Result',
    meta: {
      requiresAuth: false,
      title_cn: '',
      title_en: '',
    },
    component: () => import('../views/ResultView.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: {
      title_cn: '隐私政策',
      title_en: 'Privacy Policy',
    },
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/treaty',
    name: 'Treaty',
    meta: {
      title_cn: '用户协议',
      title_en: 'User Agreement',
    },
    component: () => import('../views/Treaty.vue')
  },
  {
    path: '/download',
    name: 'Download',
    meta: {
      title_cn: '下载万游宝 APP',
      title_en: 'Download TravelMate APP',
    },
    component: () => import('../views/Download.vue')
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title_cn: '404',
      title_en: '404',
      title_tw: '404',
    },
    component: () => import('../views/NotFoundView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
export default router
