import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './lang/en'
import cnLocale from './lang/zh-cn'
Vue.use(VueI18n)
const i18n = new VueI18n({
	// 默认语言，这里的local属性，对应message中的cn、en属性
	locale: localStorage.getItem('sim_lang')||'zh-cn',
	// 引入语言文件
	messages: {
		'zh-cn': cnLocale,
		'en': enLocale,
	},
})
export default i18n