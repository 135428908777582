import request from './request'
import md5 from "md5";
import { Toast } from 'vant';
const http ={
  /**
   * methods: 请求
   * @param url 请求地址 
   * @param params 请求参数
   * @param type 是否加载
   */
  get(url,params={},type=true){
      if(type){
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          loadingType: 'spinner',
        });
      }
      const config = {
          method: 'get',
          url
      }
      if(params) config.params = GETSIGN(params)
      return request(config)
  },
  post(url,params={},type=true){
      if(type){
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          loadingType: 'spinner',
        });
      }
      const config = {
          method: 'post',
          url
      }
      if(params) config.data = GETSIGN(params)
      return request(config)
  },
}
function GETSIGN(obj) {
	obj = {
		client: 'web',
		nonce_str: Math.random().toString(36).substring(2, 8),
    lang: localStorage.getItem("sim_lang")||'zh-cn',
		timestamp: new Date().getTime(),
		version: '1.0.0',
		...obj
	};
	let keySort = Object.keys(obj).sort();
	let str = keySort.filter(key => obj[key] || obj[key] === 0)
	.map(key => `${key}=${encodeURIComponent(obj[key])}`).join('&');
	str += '&key=sC*@5g+m';
	obj.sign = md5(str).toUpperCase();
	return obj;
}
//导出
export default http
