const LOGINAREA = [
  {
    'en': 'China',
    'cn': '中国大陆',
    'tw': '中國大陸',
    'area': '86',
    'pinyin': 'zhongguodalu'
  },
  {
    'en': 'Hong Kong region',
    'cn': '香港地区',
    'tw': '香港地區',
    'area': '852',
    'pinyin': 'xianggang'
  },
  {
    'en': 'Macau region',
    'cn': '澳门地区',
    'tw': '澳門地區',
    'area': '853',
    'pinyin': 'aomen'
  },
  {
    'en': 'Taiwan region',
    'cn': '台湾地区',
    'tw': '台灣地區',
    'area': '886',
    'pinyin': 'taiwan'
  },
  {
    'en': 'United States',
    'cn': '美国',
    'tw': '美國',
    'area': '1',
    'pinyin': 'meiguo'
  },
  {
    'en': 'Japan',
    'cn': '日本',
    'tw': '日本',
    'area': '81',
    'pinyin': 'riben'
  },
  {
    'en': 'South Korea',
    'cn': '韩国',
    'tw': '韓國',
    'area': '82',
    'pinyin': 'hanguo'
  },
  {
    'en': 'Antarctica',
    'cn': '南极洲',
    'tw': '南極洲',
    'area': '64672',
    'pinyin': 'nanjizhou'
  },
  {
    'en': 'Jamaica',
    'cn': '牙买加',
    'tw': '牙買加',
    'area': '1876',
    'pinyin': 'yamaijia'
  },
  {
    'en': 'Barbados',
    'cn': '巴巴多斯',
    'tw': '巴巴多斯',
    'area': '1246',
    'pinyin': 'babaduosi'
  },
  {
    'en': 'Saint Lucia',
    'cn': '圣卢西亚',
    'tw': '聖盧西亞',
    'area': '1758',
    'pinyin': 'shengluxiya'
  },
  {
    'en': 'Anguilla',
    'cn': '安圭拉岛',
    'tw': '安圭拉島',
    'area': '1264',
    'pinyin': 'anguiladao'
  },
  {
    'en': 'Bahamas',
    'cn': '巴哈马',
    'tw': '巴哈馬',
    'area': '1242',
    'pinyin': 'bahama'
  },
  {
    'en': 'Puerto Rico',
    'cn': '波多黎各',
    'tw': '波多黎各',
    'area': '1809',
    'pinyin': 'boduolige'
  },
  {
    'en': 'The Virgin Islands',
    'cn': '维尔京群岛',
    'tw': '維爾京群島',
    'area': '1809',
    'pinyin': 'weierjingqundao'
  },
  {
    'en': 'Wake Island',
    'cn': '威克岛',
    'tw': '威克島',
    'area': '1808',
    'pinyin': 'weikedao'
  },
  {
    'en': 'Midway island',
    'cn': '中途岛',
    'tw': '中途島',
    'area': '1808',
    'pinyin': 'zhongtudao'
  },
  {
    'en': 'Greenland',
    'cn': '格陵兰岛',
    'tw': '格陵蘭島',
    'area': '299',
    'pinyin': 'gelinglandao'
  },
  {
    'en': 'Alaska',
    'cn': '阿拉斯加',
    'tw': '阿拉斯加',
    'area': '1907',
    'pinyin': 'alasijia'
  },
  {
    'en': 'Hawaii',
    'cn': '夏威夷',
    'tw': '夏威夷',
    'area': '1808',
    'pinyin': 'xiaweiyi'
  },
  {
    'en': 'Canada',
    'cn': '加拿大',
    'tw': '加拿大',
    'area': '1',
    'pinyin': 'jianada'
  },
  {
    'en': 'Christmas island',
    'cn': '圣诞岛',
    'tw': '聖誕島',
    'area': '619164',
    'pinyin': 'shengdandao'
  },
  {
    'en': 'Norfolk Island',
    'cn': '诺福克岛',
    'tw': '諾福克島',
    'area': '6723',
    'pinyin': 'nuofukedao'
  },
  {
    'en': 'Cocos Island',
    'cn': '科科斯岛',
    'tw': '科科斯島',
    'area': '619162',
    'pinyin': 'kekesidao'
  },
  {
    'en': 'Tuvalu',
    'cn': '图瓦卢',
    'tw': '圖瓦盧',
    'area': '688',
    'pinyin': 'tuwalu'
  },
  {
    'en': 'Kiribati',
    'cn': '基里巴斯',
    'tw': '基裏巴斯',
    'area': '686',
    'pinyin': 'jilibasi'
  },
  {
    'en': 'Western Samoa',
    'cn': '西萨摩亚',
    'tw': '西薩摩亞',
    'area': '685',
    'pinyin': 'xisamoya'
  },
  {
    'en': 'Eastern Samoa',
    'cn': '东萨摩亚',
    'tw': '東薩摩亞',
    'area': '684',
    'pinyin': 'dongsamoya'
  },
  {
    'en': 'Niue',
    'cn': '纽埃岛',
    'tw': '紐埃島',
    'area': '683',
    'pinyin': 'niuaidao'
  },
  {
    'en': 'Cork Islands',
    'cn': '科克群岛',
    'tw': '科克群島',
    'area': '682',
    'pinyin': 'kekequndao'
  },
  {
    'en': 'Fiji',
    'cn': '斐济',
    'tw': '斐濟',
    'area': '679',
    'pinyin': 'fiji'
  },
  {
    'en': 'Vanuatu',
    'cn': '瓦努阿图',
    'tw': '瓦努阿圖',
    'area': '678',
    'pinyin': 'wanuatu'
  },
  {
    'en': 'Solomon Islands',
    'cn': '所罗门群岛',
    'tw': '所羅門群島',
    'area': '677',
    'pinyin': 'suoluomenqundao'
  },
  {
    'en': 'Tonga',
    'cn': '汤加',
    'tw': '湯加',
    'area': '676',
    'pinyin': 'tangjia'
  },
  {
    'en': 'Nauru',
    'cn': '瑙鲁',
    'tw': '瑙魯',
    'area': '674',
    'pinyin': 'naolu'
  },
  {
    'en': 'Guam',
    'cn': '关岛',
    'tw': '關島',
    'area': '1671',
    'pinyin': 'guandao'
  },
  {
    'en': 'New Zealand',
    'cn': '新西兰',
    'tw': '新西蘭',
    'area': '64',
    'pinyin': 'xinxilan'
  },
  {
    'en': 'Australia',
    'cn': '澳大利亚',
    'tw': '澳大利亞',
    'area': '61',
    'pinyin': 'aodaliya'
  },
  {
    'en': 'Monaco',
    'cn': '摩纳哥',
    'tw': '摩納哥',
    'area': '377',
    'pinyin': 'monage'
  },
  {
    'en': 'Faroe Islands',
    'cn': '法罗群岛',
    'tw': '法羅群島',
    'area': '298',
    'pinyin': 'faluoqundao'
  },
  {
    'en': 'Aruba',
    'cn': '阿鲁巴岛',
    'tw': '阿魯巴島',
    'area': '297',
    'pinyin': 'alubadao'
  },
  {
    'en': 'Eritrea',
    'cn': '厄立特里亚',
    'tw': '厄立特裏亞',
    'area': '291',
    'pinyin': 'eliteliya'
  },
  {
    'en': 'St. Helena',
    'cn': '圣赫勒拿',
    'tw': '聖赫勒拿',
    'area': '290',
    'pinyin': 'shenghelena'
  },
  {
    'en': 'Comoros',
    'cn': '科摩罗',
    'tw': '科摩羅',
    'area': '269',
    'pinyin': 'kemoluo'
  },
  {
    'en': 'Swaziland',
    'cn': '斯威士兰',
    'tw': '斯威士蘭',
    'area': '268',
    'pinyin': 'siweishilan'
  },
  {
    'en': 'Botswana',
    'cn': '博茨瓦纳',
    'tw': '博茨瓦納',
    'area': '267',
    'pinyin': 'bociwana'
  },
  {
    'en': 'Lesotho',
    'cn': '莱索托',
    'tw': '萊索托',
    'area': '266',
    'pinyin': 'laisuotuo'
  },
  {
    'en': 'Malawi',
    'cn': '马拉维',
    'tw': '馬拉維',
    'area': '265',
    'pinyin': 'malawei'
  },
  {
    'en': 'Namibia',
    'cn': '纳米比亚',
    'tw': '納米比亞',
    'area': '264',
    'pinyin': 'namibiya'
  },
  {
    'en': 'Zimbabwe',
    'cn': '津巴布韦',
    'tw': '津巴布韋',
    'area': '263',
    'pinyin': 'jinbabuwei'
  },
  {
    'en': 'Réunion',
    'cn': '留尼旺岛',
    'tw': '留尼旺島',
    'area': '262',
    'pinyin': 'liuniwangdao'
  },
  {
    'en': 'Madagascar',
    'cn': '马达加斯加',
    'tw': '馬達加斯加',
    'area': '261',
    'pinyin': 'madajiasijia'
  },
  {
    'en': 'Zambia',
    'cn': '赞比亚',
    'tw': '贊比亞',
    'area': '260',
    'pinyin': 'zanbiya'
  },
  {
    'en': 'Mozambique',
    'cn': '莫桑比克',
    'tw': '莫桑比克',
    'area': '258',
    'pinyin': 'mosangbike'
  },
  {
    'en': 'Burundi',
    'cn': '布隆迪',
    'tw': '布隆迪',
    'area': '257',
    'pinyin': 'bulongdi'
  },
  {
    'en': 'Uganda',
    'cn': '乌干达',
    'tw': '烏幹達',
    'area': '256',
    'pinyin': 'wuganda'
  },
  {
    'en': 'Tanzania',
    'cn': '坦桑尼亚',
    'tw': '坦桑尼亞',
    'area': '255',
    'pinyin': 'tansangniya'
  },
  {
    'en': 'Kenya',
    'cn': '肯尼亚',
    'tw': '肯尼亞',
    'area': '254',
    'pinyin': 'kenniya'
  },
  {
    'en': 'Djibouti',
    'cn': '吉布提',
    'tw': '吉布提',
    'area': '253',
    'pinyin': 'jibuti'
  },
  {
    'en': 'Somalia',
    'cn': '索马里',
    'tw': '索馬裏',
    'area': '252',
    'pinyin': 'suomali'
  },
  {
    'en': 'Ethiopia',
    'cn': '埃塞俄比亚',
    'tw': '埃塞俄比亞',
    'area': '251',
    'pinyin': 'aisaiebiya'
  },
  {
    'en': 'Rwanda',
    'cn': '卢旺达',
    'tw': '盧旺達',
    'area': '250',
    'pinyin': 'luwangda'
  },
  {
    'en': 'Sudan',
    'cn': '苏丹',
    'tw': '蘇丹',
    'area': '249',
    'pinyin': 'sudan'
  },
  {
    'en': 'Seychelles',
    'cn': '塞舌尔',
    'tw': '塞舌爾',
    'area': '248',
    'pinyin': 'saisheer'
  },
  {
    'en': 'Ascension',
    'cn': '阿森松',
    'tw': '阿森松',
    'area': '247',
    'pinyin': 'asensong'
  },
  {
    'en': 'Guinea-Bissau',
    'cn': '几内亚比绍',
    'tw': '幾內亞比紹',
    'area': '245',
    'pinyin': 'jineiyabishao'
  },
  {
    'en': 'Angola',
    'cn': '安哥拉',
    'tw': '安哥拉',
    'area': '244',
    'pinyin': 'angela'
  },
  {
    'en': 'Zaire',
    'cn': '扎伊尔',
    'tw': '紮伊爾',
    'area': '243',
    'pinyin': 'zhayier'
  },
  {
    'en': 'Congo',
    'cn': '刚果',
    'tw': '剛果',
    'area': '242',
    'pinyin': 'gangguo'
  },
  {
    'en': 'Gabon',
    'cn': '加蓬',
    'tw': '加蓬',
    'area': '241',
    'pinyin': 'jiapeng'
  },
  {
    'en': 'Equatorial Guinea',
    'cn': '赤道几内亚',
    'tw': '赤道幾內亞',
    'area': '240',
    'pinyin': 'chidaojineiya'
  },
  {
    'en': 'Principe',
    'cn': '普林西比',
    'tw': '普林西比',
    'area': '239',
    'pinyin': 'pulinxibi'
  },
  {
    'en': 'Sao Tome and Principe',
    'cn': '圣多美',
    'tw': '聖多美',
    'area': '239',
    'pinyin': 'shengduomei'
  },
  {
    'en': 'Cape Verde',
    'cn': '佛得角',
    'tw': '佛得角',
    'area': '238',
    'pinyin': 'fodejiao'
  },
  {
    'en': 'Cameroon',
    'cn': '喀麦隆',
    'tw': '喀麥隆',
    'area': '237',
    'pinyin': 'kamailong'
  },
  {
    'en': 'Central African Republic',
    'cn': '中非',
    'tw': '中非',
    'area': '236',
    'pinyin': 'zhongfei'
  },
  {
    'en': 'Chad',
    'cn': '乍得',
    'tw': '乍得',
    'area': '235',
    'pinyin': 'zhade'
  },
  {
    'en': 'Nigeria',
    'cn': '尼日利亚',
    'tw': '尼日利亞',
    'area': '234',
    'pinyin': 'niriliya'
  },
  {
    'en': 'Ghana',
    'cn': '加纳',
    'tw': '加納',
    'area': '233',
    'pinyin': 'jiana'
  },
  {
    'en': 'Sierra Leone',
    'cn': '塞拉利昂',
    'tw': '塞拉利昂',
    'area': '232',
    'pinyin': 'sailaliang'
  },
  {
    'en': 'Liberia',
    'cn': '利比里亚',
    'tw': '利比裏亞',
    'area': '231',
    'pinyin': 'libiliya'
  },
  {
    'en': 'Mauritius',
    'cn': '毛里求斯',
    'tw': '毛裏求斯',
    'area': '230',
    'pinyin': 'maoliqiusi'
  },
  {
    'en': 'Benin',
    'cn': '贝宁',
    'tw': '貝寧',
    'area': '229',
    'pinyin': 'beining'
  },
  {
    'en': 'Togo',
    'cn': '多哥',
    'tw': '多哥',
    'area': '228',
    'pinyin': 'duoge'
  },
  {
    'en': 'Niger',
    'cn': '尼日尔',
    'tw': '尼日爾',
    'area': '227',
    'pinyin': 'nirier'
  },
  {
    'en': 'Burkina Faso',
    'cn': '布基拉法索',
    'tw': '布基拉法索',
    'area': '226',
    'pinyin': 'bujilafasuo'
  },
  {
    'en': "Côte d'Ivoire",
    'cn': '科特迪瓦',
    'tw': '科特迪瓦',
    'area': '225',
    'pinyin': 'ketediwa'
  },
  {
    'en': 'Guinea',
    'cn': '几内亚',
    'tw': '幾內亞',
    'area': '224',
    'pinyin': 'jineiya'
  },
  {
    'en': 'Mali',
    'cn': '马里',
    'tw': '馬裏',
    'area': '223',
    'pinyin': 'mali'
  },
  {
    'en': 'Mauritania',
    'cn': '毛里塔尼亚',
    'tw': '毛裏塔尼亞',
    'area': '222',
    'pinyin': 'maolitaniya'
  },
  {
    'en': 'Senegal',
    'cn': '塞内加尔',
    'tw': '塞內加爾',
    'area': '221',
    'pinyin': 'saineijiaer'
  },
  {
    'en': 'Gambia',
    'cn': '冈比亚',
    'tw': '岡比亞',
    'area': '220',
    'pinyin': 'gangbiya'
  },
  {
    'en': 'Libya',
    'cn': '利比亚',
    'tw': '利比亞',
    'area': '218',
    'pinyin': 'libiya'
  },
  {
    'en': 'Tunisia',
    'cn': '突尼斯',
    'tw': '突尼斯',
    'area': '216',
    'pinyin': 'tunisi'
  },
  {
    'en': 'Algeria',
    'cn': '阿尔及利亚',
    'tw': '阿爾及利亞',
    'area': '213',
    'pinyin': 'aerjiliya'
  },
  {
    'en': 'Morocco',
    'cn': '摩洛哥',
    'tw': '摩洛哥',
    'area': '212',
    'pinyin': 'moluoge'
  },
  {
    'en': 'South Africa',
    'cn': '南非',
    'tw': '南非',
    'area': '27',
    'pinyin': 'nanfei'
  },
  {
    'en': 'Egypt',
    'cn': '埃及',
    'tw': '埃及',
    'area': '20',
    'pinyin': 'aiji'
  },
  {
    'en': 'Uruguay',
    'cn': '乌拉圭',
    'tw': '烏拉圭',
    'area': '598',
    'pinyin': 'wulagui'
  },
  {
    'en': 'Suriname',
    'cn': '苏里南',
    'tw': '蘇裏南',
    'area': '597',
    'pinyin': 'sulinan'
  },
  {
    'en': 'Martinique',
    'cn': '马提尼克',
    'tw': '馬提尼克',
    'area': '596',
    'pinyin': 'matinike'
  },
  {
    'en': 'Paraguay',
    'cn': '巴拉圭',
    'tw': '巴拉圭',
    'area': '595',
    'pinyin': 'balagui'
  },
  {
    'en': 'French Guiana',
    'cn': '法属圭亚那',
    'tw': '法屬圭亞那',
    'area': '594',
    'pinyin': 'fashuguiyana'
  },
  {
    'en': 'Ecuador',
    'cn': '厄瓜多尔',
    'tw': '厄瓜多爾',
    'area': '593',
    'pinyin': 'eguaduoer'
  },
  {
    'en': 'Guyana',
    'cn': '圭亚那',
    'tw': '圭亞那',
    'area': '592',
    'pinyin': 'guiyana'
  },
  {
    'en': 'Bolivia',
    'cn': '玻利维亚',
    'tw': '玻利維亞',
    'area': '591',
    'pinyin': 'boliweiya'
  },
  {
    'en': 'Guadeloupe',
    'cn': '瓜德罗普',
    'tw': '瓜德羅普',
    'area': '590',
    'pinyin': 'guadeluopu'
  },
  {
    'en': 'Haiti',
    'cn': '海地',
    'tw': '海地',
    'area': '509',
    'pinyin': 'haidi'
  },
  {
    'en': 'Saint Pierre',
    'cn': '圣彼埃尔',
    'tw': '聖彼埃爾',
    'area': '508',
    'pinyin': 'shengbiaier'
  },
  {
    'en': 'Panama',
    'cn': '巴拿马',
    'tw': '巴拿馬',
    'area': '507',
    'pinyin': 'banama'
  },
  {
    'en': 'Costa Rica',
    'cn': '哥斯达黎加',
    'tw': '哥斯達黎加',
    'area': '506',
    'pinyin': 'gesidalijia'
  },
  {
    'en': 'Nicaragua',
    'cn': '尼加拉瓜',
    'tw': '尼加拉瓜',
    'area': '505',
    'pinyin': 'nijialagua'
  },
  {
    'en': 'Honduras',
    'cn': '洪都拉斯',
    'tw': '洪都拉斯',
    'area': '504',
    'pinyin': 'hongdulasi'
  },
  {
    'en': 'El Salvador',
    'cn': '萨尔瓦多',
    'tw': '薩爾瓦多',
    'area': '503',
    'pinyin': 'saerwaduo'
  },
  {
    'en': 'Guatemala',
    'cn': '危地马拉',
    'tw': '危地馬拉',
    'area': '502',
    'pinyin': 'weidimala'
  },
  {
    'en': 'Belize',
    'cn': '伯利兹',
    'tw': '伯利茲',
    'area': '501',
    'pinyin': 'bolizi'
  },
  {
    'en': 'Falkland Islands',
    'cn': '福克兰群岛',
    'tw': '福克蘭群島',
    'area': '500',
    'pinyin': 'fukelanqundao'
  },
  {
    'en': 'Venezuela',
    'cn': '委内瑞拉',
    'tw': '委內瑞拉',
    'area': '58',
    'pinyin': 'weineiruila'
  },
  {
    'en': 'Colombia',
    'cn': '哥伦比亚',
    'tw': '哥倫比亞',
    'area': '57',
    'pinyin': 'gelunbiya'
  },
  {
    'en': 'Chile',
    'cn': '智利',
    'tw': '智利',
    'area': '56',
    'pinyin': 'zhili'
  },
  {
    'en': 'Brazil',
    'cn': '巴西',
    'tw': '巴西',
    'area': '55',
    'pinyin': 'baxi'
  },
  {
    'en': 'Argentina',
    'cn': '阿根廷',
    'tw': '阿根廷',
    'area': '54',
    'pinyin': 'agenting'
  },
  {
    'en': 'Cuba',
    'cn': '古巴',
    'tw': '古巴',
    'area': '53',
    'pinyin': 'guba'
  },
  {
    'en': 'Mexico',
    'cn': '墨西哥',
    'tw': '墨西哥',
    'area': '52',
    'pinyin': 'moxige'
  },
  {
    'en': 'Peru',
    'cn': '秘鲁',
    'tw': '秘魯',
    'area': '51',
    'pinyin': 'milu'
  },
  {
    'en': 'Liechtenstein',
    'cn': '列支敦士登',
    'tw': '列支敦士登',
    'area': '423',
    'pinyin': 'liezhidunshideng'
  },
  {
    'en': 'Slovakia',
    'cn': '斯洛伐克',
    'tw': '斯洛伐克',
    'area': '421',
    'pinyin': 'siluofake'
  },
  {
    'en': 'Czech Republic',
    'cn': '捷克',
    'tw': '捷克',
    'area': '420',
    'pinyin': 'jieke'
  },
  {
    'en': 'Vatican',
    'cn': '梵蒂冈',
    'tw': '梵蒂岡',
    'area': '379',
    'pinyin': 'fandigang'
  },
  {
    'en': 'Macedonia',
    'cn': '马其顿',
    'tw': '馬其頓',
    'area': '389',
    'pinyin': 'maqidun'
  },
  {
    'en': 'Bosnia and Herzegovina',
    'cn': '波黑',
    'tw': '波黑',
    'area': '387',
    'pinyin': 'bohei'
  },
  {
    'en': 'Slovenia',
    'cn': '斯洛文尼亚',
    'tw': '斯洛文尼亞',
    'area': '386',
    'pinyin': 'siluowenniya'
  },
  {
    'en': 'Croatia',
    'cn': '克罗地亚',
    'tw': '克羅地亞',
    'area': '385',
    'pinyin': 'keluodiya'
  },
  {
    'en': 'Ukraine',
    'cn': '乌克兰',
    'tw': '烏克蘭',
    'area': '380',
    'pinyin': 'wukelan'
  },
  {
    'en': 'Andorra',
    'cn': '安道尔共和国',
    'tw': '安道爾共和國',
    'area': '376',
    'pinyin': 'andaoergongheguo'
  },
  {
    'en': 'Moldova',
    'cn': '摩尔多瓦',
    'tw': '摩爾多瓦',
    'area': '373',
    'pinyin': 'moerduowa'
  },
  {
    'en': 'Estonia',
    'cn': '爱沙尼亚',
    'tw': '愛沙尼亞',
    'area': '372',
    'pinyin': 'aishaniya'
  },
  {
    'en': 'Latvia',
    'cn': '拉脱维亚',
    'tw': '拉脫維亞',
    'area': '371',
    'pinyin': 'latuoweiya'
  },
  {
    'en': 'Lithuania',
    'cn': '立陶宛',
    'tw': '立陶宛',
    'area': '370',
    'pinyin': 'litaowan'
  },
  {
    'en': 'Bulgaria',
    'cn': '保加利亚',
    'tw': '保加利亞',
    'area': '359',
    'pinyin': 'baojialiya'
  },
  {
    'en': 'Finland',
    'cn': '芬兰',
    'tw': '芬蘭',
    'area': '358',
    'pinyin': 'fenlan'
  },
  {
    'en': 'Cyprus',
    'cn': '塞浦路斯',
    'tw': '塞浦路斯',
    'area': '357',
    'pinyin': 'saipulusi'
  },
  {
    'en': 'Malta',
    'cn': '马耳他',
    'tw': '馬耳他',
    'area': '356',
    'pinyin': 'maerta'
  },
  {
    'en': 'Albania',
    'cn': '阿尔巴尼亚',
    'tw': '阿爾巴尼亞',
    'area': '355',
    'pinyin': 'aerbaniya'
  },
  {
    'en': 'Iceland',
    'cn': '冰岛',
    'tw': '冰島',
    'area': '354',
    'pinyin': 'bingdao'
  },
  {
    'en': 'Ireland',
    'cn': '爱尔兰',
    'tw': '愛爾蘭',
    'area': '353',
    'pinyin': 'aierlan'
  },
  {
    'en': 'Luxembourg',
    'cn': '卢森堡',
    'tw': '盧森堡',
    'area': '352',
    'pinyin': 'lusenbao'
  },
  {
    'en': 'Portugal',
    'cn': '葡萄牙',
    'tw': '葡萄牙',
    'area': '351',
    'pinyin': 'putaoya'
  },
  {
    'en': 'Gibraltar',
    'cn': '直布罗陀',
    'tw': '直布羅陀',
    'area': '350',
    'pinyin': 'zhibuluotuo'
  },
  {
    'en': 'Yugoslavia',
    'cn': '南斯拉夫',
    'tw': '南斯拉夫',
    'area': '381',
    'pinyin': 'nansilafu'
  },
  {
    'en': 'San Marino',
    'cn': '圣马力诺',
    'tw': '聖馬力諾',
    'area': '223',
    'pinyin': 'shengmalinuo'
  },
  {
    'en': 'Poland',
    'cn': '波兰',
    'tw': '波蘭',
    'area': '48',
    'pinyin': 'bolan'
  },
  {
    'en': 'Norway',
    'cn': '挪威',
    'tw': '挪威',
    'area': '47',
    'pinyin': 'nuowei'
  },
  {
    'en': 'Sweden',
    'cn': '瑞典',
    'tw': '瑞典',
    'area': '46',
    'pinyin': 'ruidian'
  },
  {
    'en': 'Denmark',
    'cn': '丹麦',
    'tw': '丹麥',
    'area': '45',
    'pinyin': 'danmai'
  },
  {
    'en': 'Austria',
    'cn': '奥地利',
    'tw': '奧地利',
    'area': '43',
    'pinyin': 'aodili'
  },
  {
    'en': 'Switzerland',
    'cn': '瑞士',
    'tw': '瑞士',
    'area': '41',
    'pinyin': 'ruishi'
  },
  {
    'en': 'Romania',
    'cn': '罗马尼亚',
    'tw': '羅馬尼亞',
    'area': '40',
    'pinyin': 'luomaniya'
  },
  {
    'en': 'Hungary',
    'cn': '匈牙利',
    'tw': '匈牙利',
    'area': '36',
    'pinyin': 'xiongyali'
  },
  {
    'en': 'Spain',
    'cn': '西班牙',
    'tw': '西班牙',
    'area': '34',
    'pinyin': 'xibanya'
  },
  {
    'en': 'Belgium',
    'cn': '比利时',
    'tw': '比利時',
    'area': '32',
    'pinyin': 'bilishi'
  },
  {
    'en': 'Netherlands',
    'cn': '荷兰',
    'tw': '荷蘭',
    'area': '31',
    'pinyin': 'helan'
  },
  {
    'en': 'Greece',
    'cn': '希腊',
    'tw': '希臘',
    'area': '30',
    'pinyin': 'xila'
  },
  {
    'en': 'Russia',
    'cn': '俄罗斯',
    'tw': '俄羅斯',
    'area': '7',
    'pinyin': 'eluosi'
  },
  {
    'en': 'France',
    'cn': '法国',
    'tw': '法國',
    'area': '33',
    'pinyin': 'faguo'
  },
  {
    'en': 'Italy',
    'cn': '意大利',
    'tw': '意大利',
    'area': '39',
    'pinyin': 'yidali'
  },
  {
    'en': 'Germany',
    'cn': '德国',
    'tw': '德國',
    'area': '49',
    'pinyin': 'deguo'
  },
  {
    'en': 'United Kingdom',
    'cn': '英国',
    'tw': '英國',
    'area': '44',
    'pinyin': 'yingguo'
  },
  {
    'en': 'Uzbekistan',
    'cn': '乌兹别克斯坦',
    'tw': '烏茲別克斯坦',
    'area': '998',
    'pinyin': 'wuzibiekesitan'
  },
  {
    'en': 'Kyrgyzstan',
    'cn': '吉尔吉斯斯坦',
    'tw': '吉爾吉斯斯坦',
    'area': '996',
    'pinyin': 'jierjisisitan'
  },
  {
    'en': 'Georgia',
    'cn': '乔治亚',
    'tw': '喬治亞',
    'area': '995',
    'pinyin': 'qiaozhiya'
  },
  {
    'en': 'Azerbaijan',
    'cn': '阿塞拜疆',
    'tw': '阿塞拜疆',
    'area': '994',
    'pinyin': 'asaibaijiang'
  },
  {
    'en': 'Turkmenistan',
    'cn': '土库曼斯坦',
    'tw': '土庫曼斯坦',
    'area': '993',
    'pinyin': 'tukumansitan'
  },
  {
    'en': 'Nepal',
    'cn': '尼泊尔',
    'tw': '尼泊爾',
    'area': '977',
    'pinyin': 'niboer'
  },
  {
    'en': 'Mongolia',
    'cn': '蒙古',
    'tw': '蒙古',
    'area': '976',
    'pinyin': 'menggu'
  },
  {
    'en': 'Bhutan',
    'cn': '不丹',
    'tw': '不丹',
    'area': '975',
    'pinyin': 'budan'
  },
  {
    'en': 'Qatar',
    'cn': '卡塔尔',
    'tw': '卡塔爾',
    'area': '974',
    'pinyin': 'kataer'
  },
  {
    'en': 'Bahrain',
    'cn': '巴林',
    'tw': '巴林',
    'area': '973',
    'pinyin': 'balin'
  },
  {
    'en': 'Israel',
    'cn': '以色列',
    'tw': '以色列',
    'area': '972',
    'pinyin': 'yiselie'
  },
  {
    'en': 'United Arab Emirates',
    'cn': '阿联酋',
    'tw': '阿聯酋',
    'area': '971',
    'pinyin': 'alianqiu'
  },
  {
    'en': 'Palestine',
    'cn': '巴勒斯坦',
    'tw': '巴勒斯坦',
    'area': '970',
    'pinyin': 'balesitan'
  },
  {
    'en': 'Oman',
    'cn': '阿曼',
    'tw': '阿曼',
    'area': '968',
    'pinyin': 'aman'
  },
  {
    'en': 'Yemen',
    'cn': '也门',
    'tw': '也門',
    'area': '967',
    'pinyin': 'yemen'
  },
  {
    'en': 'Saudi Arabia',
    'cn': '沙特阿拉伯',
    'tw': '沙特阿拉伯',
    'area': '966',
    'pinyin': 'shatealabo'
  },
  {
    'en': 'Kuwait',
    'cn': '科威特',
    'tw': '科威特',
    'area': '965',
    'pinyin': 'keweite'
  },
  {
    'en': 'Iraq',
    'cn': '伊拉克',
    'tw': '伊拉克',
    'area': '964',
    'pinyin': 'yilake'
  },
  {
    'en': 'Syrian Arab Republic',
    'cn': '叙利亚',
    'tw': '敘利亞',
    'area': '963',
    'pinyin': 'xuliya'
  },
  {
    'en': 'Jordan',
    'cn': '约旦',
    'tw': '約旦',
    'area': '962',
    'pinyin': 'yuedan'
  },
  {
    'en': 'Lebanon',
    'cn': '黎巴嫩',
    'tw': '黎巴嫩',
    'area': '961',
    'pinyin': 'libanen'
  },
  {
    'en': 'Maldives',
    'cn': '马尔代夫',
    'tw': '馬爾代夫',
    'area': '960',
    'pinyin': 'maerdaifu'
  },
  {
    'en': 'Bangladesh',
    'cn': '孟加拉国',
    'tw': '孟加拉國',
    'area': '880',
    'pinyin': 'mengjialaguo'
  },
  {
    'en': 'Laos',
    'cn': '老挝',
    'tw': '老撾',
    'area': '856',
    'pinyin': 'laowo'
  },
  {
    'en': 'Cambodia',
    'cn': '柬埔寨',
    'tw': '柬埔寨',
    'area': '855',
    'pinyin': 'jianpuzhai'
  },
  {
    'en': 'North Korea',
    'cn': '朝鲜',
    'tw': '朝鮮',
    'area': '850',
    'pinyin': 'chaoxian'
  },
  {
    'en': 'Brunei',
    'cn': '文莱',
    'tw': '文萊',
    'area': '673',
    'pinyin': 'wenlai'
  },
  {
    'en': 'Timor-Leste',
    'cn': '东帝汶',
    'tw': '東帝汶',
    'area': '670',
    'pinyin': 'dongdiwen'
  },
  {
    'en': 'Armenia',
    'cn': '亚美尼亚',
    'tw': '亞美尼亞',
    'area': '374',
    'pinyin': 'yameiniya'
  },
  {
    'en': 'Iran',
    'cn': '伊朗',
    'tw': '伊朗',
    'area': '98',
    'pinyin': 'yilang'
  },
  {
    'en': 'Myanmar',
    'cn': '缅甸',
    'tw': '緬甸',
    'area': '95',
    'pinyin': 'miandian'
  },
  {
    'en': 'Sri Lanka',
    'cn': '斯里兰卡',
    'tw': '斯裏蘭卡',
    'area': '94',
    'pinyin': 'sililanka'
  },
  {
    'en': 'Afghanistan',
    'cn': '阿富汗',
    'tw': '阿富汗',
    'area': '93',
    'pinyin': 'afuhan'
  },
  {
    'en': 'Pakistan',
    'cn': '巴基斯坦',
    'tw': '巴基斯坦',
    'area': '92',
    'pinyin': 'bajisitan'
  },
  {
    'en': 'India',
    'cn': '印度',
    'tw': '印度',
    'area': '91',
    'pinyin': 'yindu'
  },
  {
    'en': 'Turkey',
    'cn': '土耳其',
    'tw': '土耳其',
    'area': '90',
    'pinyin': 'tuerqi'
  },
  {
    'en': 'Vietnam',
    'cn': '越南',
    'tw': '越南',
    'area': '84',
    'pinyin': 'yuenan'
  },
  {
    'en': 'Kazakhstan',
    'cn': '哈萨克斯坦',
    'tw': '哈薩克斯坦',
    'area': '7',
    'pinyin': 'hasakesitan'
  },
  {
    'en': 'Tajikistan',
    'cn': '塔吉克斯坦',
    'tw': '塔吉克斯坦',
    'area': '7',
    'pinyin': 'tajikesitan'
  },
  {
    'en': 'Thailand',
    'cn': '泰国',
    'tw': '泰國',
    'area': '66',
    'pinyin': 'taiguo'
  },
  {
    'en': 'Singapore',
    'cn': '新加坡',
    'tw': '新加坡',
    'area': '65',
    'pinyin': 'xinjiapo'
  },
  {
    'en': 'Philippines',
    'cn': '菲律宾',
    'tw': '菲律賓',
    'area': '63',
    'pinyin': 'feilvbin'
  },
  {
    'en': 'Indonesia',
    'cn': '印度尼西亚',
    'tw': '印度尼西亞',
    'area': '62',
    'pinyin': 'yindunixiya'
  },
  {
    'en': 'Malaysia',
    'cn': '马来西亚',
    'tw': '馬來西亞',
    'area': '60',
    'pinyin': 'malaixiya'
  },

]

export { LOGINAREA }
