const APIURL = {
  HOME:{
    ERR:'common/errorTest',
    CHECKTOKEN:'v2/common/checkToken',//token校验
    DOWNLOAD:'v1/Ucenter/latestversion',//app下载
    INDEX:'v1/index/h5Index',//商城首页
    DETAILS:'v1/product/h5Info',//商城详情
    PRICE:'v1/product/getPrice',//获取价格
    DAYS:'v1/product/getDaysBySpec',//获取天数
    OTHER:'v1/product/otherProduct',//其它套餐
    SEARCH:'v1/index/shopCountryListByWords', //目的地搜索
  },
  PUBLIC: {
    USER:'v1/user/getUserInfo',//用户信息
    LOGIN:'v1/user/login', // 登录
    REGISTER:'v1/user/h5register',// 注册
    LOGOUT:'v1/user/logout', // 登出
    GETCODE:'v1/user/sendCode', //获取验证码
    RESETPWD:'v1/user/resetpwd', //忘记密码
    BINDEMAIL:'v1/user/changeemail',//绑定邮箱
    BINDMOBILE:'v1/user/changemobile',//绑定手机
    CHANGEPWD:'v1/user/changepwd', // 修改密码
    GUIDE:'v1/ucenter/h5guidelist',//使用帮助
    INVITE:'v3/user/user/getInviteInfo', //获取邀请信息
    INVITERULE:'v3/coupon/act/getActInfo' //邀请规则
  },
  DESTINATION: {
    STATELIST:'v10/product/stateList', //目的地列表
  },
  MYCARDS:{
    CARDS:'/v1/sim/lists', //我的上网卡
    BIND:'/v1/simcard/addSimcard',//添加卡
    UNBIND:'/v3/simcard/simcard/deletesimcard',//解绑
    STOP:'/v1/simplan/terminate',//停止
    USE:'/v1/simplan/priority',//优先使用
    HASPLAN:'/v1/simcard/available_package'//检查上网卡是否有套餐
  },
  MYDATA: {
    LIST:'v10/dataplan/validList', // 套餐管理列表
    DETAIL:'v10/product/info', // 套餐详情
    PLANDETAIL:'/v1/order/getSimplanDetailById',
    HISTORY: 'v10/dataplan/h5ExpiredList', //历史套餐
    ADDRESSLIST:'v1/ucenter/address',//收货地址列表
    ADDADDRESS:'v1/ucenter/addaddress',//添加收货地址
    EDITADDRESS:'v1/ucenter/changeaddress',//编辑收货地址
    DELADDRESS:'v1/ucenter/deladdress',//删除收货地址
    DETAILADDRESS:'v1/ucenter/detailaddress',//查看收货地址
  },
  ORDER: {
    PAYCODE:'v1/Payment/create',//扫码付款
    SIMORDER:'v1/order/createPurchaseSimOrder', //购卡下单
    FLOWORDER:'v1/order/createChargePlanOrder',//充值下单,
    CHECK:'v1/simcard/checkSimcard',//上网卡校验
    PAY:'v1/Payment/h5Confirm',//支付方式
    KDLIST:'v1/pub/expresslists',//快递列表
    SIMLIST:'v1/uorder/simorder', //购卡订单列表
    FLOWLIST:'v1/uorder/planorder', //流量订单列表
    TRACES:'v1/uorder/getExpressTrace', // 物流详情
    CANCEL:'v10/order/cancel', // 取消订单
    SIMSTATUS:'v1/uorder/simorderstatus', //购卡订单查询
    FLOWSTATUS:'v1/uorder/planorderstatus', //流量订单查询
    COMPLETE:'v1/uorder/simordercomplete'//确认收货
  }
}
export default APIURL