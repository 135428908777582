<template>
  <div class="comment-bar">
    <div class="container title">
      {{ $t('reviews.title') }}
      <div class="flex-center-start">
        {{ $t('reviews.add_up') }}
        <img class="star" src="../assets/reviews/star.png" alt="" srcset="">
        {{ $t('reviews.reviews') }}
      </div>		
    </div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="swiper-slide" v-for="(item,index) in reviews[$i18n.locale]" :key="index">
        <div>
          <div class="flex-center-between">
            <img class="icon" src="../assets/reviews/icon.png" alt="" srcset="">
            <span>{{ item.user }}</span>
          </div>
          <div class="content">{{ item.content }}</div>
        </div>
        <div class="flex-center-between">
          <img class="star" src="../assets/reviews/star.png" alt="" srcset="">
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name:'ReviewsBar',
  components:{
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        speed:2000,
        autoplay: {
          delay: 2500,
          pauseOnMouseEnter:true,
          waitForTransition:true,
          disableOnInteraction: false,
        },
        centeredSlides: true,
        breakpoints: { 
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 1.6,
            spaceBetween: 30
          },
          768: {  //当屏幕宽度大于等于768 
            slidesPerView: 3,
            spaceBetween: 40
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 60
          }
        }
      },
      reviews:{
        'zh-cn':[
          {
            user:'Lay****',
            content:'去台中4天旅游，在APP买的SIM卡，非常方便，下单2-3天已寄到家中，省去很多时间。去台中期间全程接收良好，无论在巿中心还是阿里山，网络速度也很快，非常值得推荐。'
          },
          {
            user:'T****',
            content:'当飞机飞到欧洲上空时换上SIM卡，飞机落地后开启手机漫游，下机后马上就可使用，网络用起来顺畅，SIM卡还可以充值其他套餐下次再使用，好方便。'
          },
          {
            user:'Ste****',
            content:'寄件迅速，直接宅配到府超方便！在欧洲使用网络稳定，没有掉线。 下次有需要时一定会再回购！'
          },
          {
            user:'K****',
            content:'难得在瑞士也可以流畅使用！网速正常，推介给会去瑞士的人。'
          },
          {
            user:'Tr****',
            content:'我提早一个月在台湾预定，网卡是从香港寄来。到韩国时使用完全没有困难，非常的便利稳定，拍照上传以及line通话迅速，价格也很优惠，相当值得推荐。'
          },
        ],
        'en':[
          {
            user:'Me****',
            content:'Initially bought 2 sim cards to be used in Taiwan, then bought another 2 for usage in Korea. The staff managed to help me configure the card so that I can use the card in both countries without the hassle of changing sim cards. Signal was good thoughout the whole vacation.',
          },
          {
            user:'Ni****',
            content:'Nice connection speed and value for money. Takes a few mins before the internet can connect after arrival. But then it was smooth throughout my trip. Even when the power is off and restarted again, it will automatically connect smoothly.',
          },
          {
            user:'Ge****',
            content:'Book from Singapore, items sent to Taiwan hotel and you don’t have to pick up at the airport. Seller is kind enough to contact the hotel to receive behalf of the guest. Highly recommended.',
          },
          {
            user:'Nu**',
            content:`Just one SIM card for multi country travel ( I've been AMS/MAN/FRA/LHR for this trip ), love this soooooo much.`,
          },
          {
            user:'Pa****',
            content:'We ordered 3 of them for us and our friends. The price was great. We ordered them and received them in a few days. They are very easy to use and worked well. Thank you',
          },
        ]
      }
    }
  },
}
</script>
<style scoped lang="scss">
  .comment-bar{
    .title{
      color:#222222;
      font-size: 32px;
      line-height: 2;
      img{
        margin: 0 6px;
        width: 200px;
        height: auto;
      }
    }
    .swiper {
      padding: 80px 0;
      .swiper-slide {
        color: #333333;
        font-size: 18px;
        opacity: 0.4;
        height: 370px;
        padding: 40px;
        border-radius: 10px;
        box-shadow: 16px 16px 48px 0px rgba(0,0,0,0.1);
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .icon{
          width: 23px;
          height: auto;
        }
        .star{
          width: 100px;
          height: auto;
        }
        .flag{
          margin-right: 8px;
          width: 20px;
          height: auto;
        }
        .content{
          margin-top: 30px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7; /* 显示的行数 */
          overflow: hidden; /* 溢出隐藏 */
          text-overflow: ellipsis; /* 文本溢出显示省略号 */
        }
      }
      .swiper-slide-active{
        opacity: 1;
        transform: scale(1.1);
      }
      .swiper-slide-prev,.swiper-slide-next{
        opacity: 1;
      }
    }
    @include respond-to('phone'){
      .title{
        font-size: 18px;
        img{
          margin: 0 6px;
          width: 100px;
          height: auto;
        }
      }
      .swiper {
        padding: 40px 0;
        .swiper-slide {
          padding: 16px;
          font-size: 14px;
          height: 240px;
          .icon{
            width: 18px;
            height: auto;
          }
          .star{
            width: 60px;
            height: auto;
          }
          .flag{
            margin-right: 4px;
            width: 16px;
            height: auto;
          }
          .content{
            margin-top: 20px;
            -webkit-line-clamp: 5; /* 显示的行数 */
          }
        }
      
      }
      @media (max-width: 320px) {
        .title{
          font-size: 16px;
          line-height: 1.5;
          img{
            margin: 0 6px;
            width: 80px;
            height: auto;
          }
        }
        .swiper {
          .swiper-slide {
            padding: 10px;
            font-size: 12px;
            height: 200px;
            .icon{
              width: 16px;
              height: auto;
            }
           
          }
        }
      }
    }
    @include respond-to('pad'){
      .title{
        font-size: 20px;
        img{
          margin: 0 6px;
          width: 100px;
          height: auto;
        }
      }
      .swiper {
        padding: 40px 0;
        .swiper-slide {
          padding: 20px;
          font-size: 14px;
          height: 260px;
          .star{
            width: 70px;
            height: auto;
          }
          .flag{
            margin-right: 8px;
            width: 20px;
            height: auto;
          }
          .content{
            margin-top: 20px;
            -webkit-line-clamp: 6; /* 显示的行数 */
          }
        }
      }
    }
  }
</style>