<template>
  <div id="app">
    <navigation-bar v-if="!isApp"/>
    <keep-alive exclude="Topup,MyCards,Details,Mine,Pay,Search">
      <router-view style="flex:1;" :key="$route.fullPath"/>
    </keep-alive>
    <footer-bar v-if="!isApp"/>
    <login-modal/>
    <float-button/>
  </div>
</template>
<script>
import { mapState,mapGetters,mapActions } from 'vuex';
import LoginModal from './components/LoginModal.vue';
import FooterBar from './components/FooterBar.vue';
import NavigationBar from './components/NavigationBar.vue';
import FloatButton from './components/FloatButton.vue';
export default {
  components: { 
    LoginModal,
    NavigationBar,
    FooterBar,
    FloatButton 
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['isApp']),
    ...mapGetters('user', ['isLogin']),
  },
  async mounted(){
    if (this.isLogin&&!this.isApp) this.GetInfo() // 获取用户信息
  },
  methods: {
    ...mapActions('user', ['GetInfo']),
  },
}
</script>
<style lang="scss">
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
