<template>
  <a-modal :visible="loginModalVisible" :footer="null" :width="410" :title="formTitle" :destroyOnClose="true"
           @cancel="handleCancel" wrapClassName="login-modal">
    <a-spin :spinning="isLoading" size="large">
      <div @click.stop="dropdown = false">
        <div class="login-modal-content" v-if="type == 0">
          <div class="form">
            <van-form @submit="handleLogin">
              <van-field class="input" type="text" clearable v-model="login.text" :placeholder="$t('login.tips_1')"
                         :rules="[{ required: true, message: $t('login.tips_1') }]" />
              <van-field class="input" type="password" clearable v-model="login.pwd" :placeholder="$t('login.tips_3')"
                         :rules="[{ required: true, message: $t('login.tips_3') }]" />
              <van-button color='#2564F6' :disabled="isDisabled(login)" type="primary" native-type="submit" block>{{
                $t('route.login') }}</van-button>
            </van-form>
            <div class="tab flex-center-end">
              <span @click="type = 1">{{ $t('login.title_3') }}</span> &nbsp; | &nbsp;
              <span @click="type = 2">{{ $t('login.title_2') }}</span>
            </div>
            <div class="text">{{ $t('login.text_1') }}
              <span class="colorBtn" @click="routerClick(1)">{{ $t('login.treaty_1') }}</span>{{ $t('login.text') }}
              <span class="colorBtn" @click="routerClick(0)">{{ $t('login.treaty_2') }}</span>
            </div>
          </div>
        </div>
        <div class="login-modal-content" v-if="type == 1">
          <div class="form">
            <div class="tab" v-if="reset.type == 'email'" style="margin-bottom: 20px;">
              {{ $t('login.text_2') }}<span class="colorBtn"
                    @click="reset.type = 'mobile', reset.area = area, reset.text = ''">{{ $t('login.tab_1') }}</span>
            </div>
            <div class="tab" v-else style="margin-bottom: 20px;">
              {{ $t('login.text_3') }}<span class="colorBtn"
                    @click="reset.type = 'email', reset.area = '', reset.text = ''">{{ $t('login.tab_2') }}</span>
            </div>
            <van-form @submit="handleReset">
              <div class="dropdown box flex-center-start ">
                <div class="area flex" v-show="reset.type == 'mobile'" @click.stop="dropdown = !dropdown">
                  +{{ reset.area }}
                </div>
                <van-field class="input" @input="change(reset.text)" v-model="reset.text" clearable
                           :placeholder="$t('login.tips_1')"
                           :rules="[{ required: true, message: $t('login.tips_1') }]" />
                <ul class="dropdown-menu" v-show="dropdown">
                  <li class="flex-center-between" @click.stop>{{ $t('login.area') }}</li>
                  <li class="flex-center-between" :class="{ colorBtn: item.area == reset.area }"
                      v-for="(item, index) in areaList" :key="index"
                      @click="reset.area = area = item.area, dropdown = false">
                    <span v-if="$i18n.locale == 'zh-cn'">{{ item.cn }}</span>
                    <span v-else-if="$i18n.locale == 'zh-tw'">{{ item.tw }}</span>
                    <span v-else>{{ item.en }}</span>
                    <span>+{{ item.area }}</span>
                  </li>
                </ul>
              </div>
              <van-field class="input" v-model="reset.code" clearable :placeholder="$t('login.tips_4')"
                         :rules="[{ required: true, message: $t('login.tips_4') }]">
                <template #button>
                  <span class="colorBtn" v-show="!isStart" @click.stop="start(reset.text, 'resetpwd', reset.area)">{{
                    $t('login.tips_5') }}</span>
                  <van-count-down ref="countDown" v-show="isStart" @finish="finish" :time="60000" :auto-start="false"
                                  format="ss s" style="color:#2564F6;" />
                </template>
              </van-field>
              <van-field class="input" :type="checkPwd" clearable v-model="reset.pwd" :placeholder="$t('login.tips_8')"
                         :rules="[{ required: true, message: $t('login.tips_8') }]">
                <template #button>
                  <div class="flex">
                    <van-icon @click="check(1)" v-show="!isCheck" name="closed-eye" size="18px"
                              style="cursor: pointer;" />
                    <van-icon @click="check(0)" v-show="isCheck" name="eye-o" size="18px" style="cursor: pointer;" />
                  </div>
                </template>
              </van-field>
              <van-field class="input" :type="checkPwd" clearable v-model="reset.newpassword"
                         :placeholder="$t('login.tips_9')" :rules="[{ required: true, message: $t('login.tips_9') }]">
                <template #button>
                  <div class="flex">
                    <van-icon @click="check(1)" v-show="!isCheck" name="closed-eye" size="18px"
                              style="cursor: pointer;" />
                    <van-icon @click="check(0)" v-show="isCheck" name="eye-o" size="18px" style="cursor: pointer;" />
                  </div>
                </template>
              </van-field>
              <van-button color='#2564F6' :disabled="isDisabled(reset)" type="primary" native-type="submit" block>{{
                $t('common.ok')
                }}</van-button>
            </van-form>
            <div class="tab flex-center-end">
              <span style="color:#2564F6;" @click="type = 0, finish()">{{ $t('login.tips_10') }}</span>
            </div>
          </div>
        </div>
        <div class="login-modal-content" v-if="type == 2">
          <div class="form">
            <van-form @submit="handleRegister">
              <div class="dropdown box flex-center-start">
                <div class="area flex" v-show="!register.type" @click.stop="dropdown = !dropdown">
                  +{{ register.area }}
                </div>
                <van-field class="input" @input="change(register.text)" v-model="register.text" clearable
                           :placeholder="$t('login.tips_1')"
                           :rules="[{ required: true, message: $t('login.tips_1') }]" />
                <ul class="dropdown-menu" v-show="dropdown">
                  <li class="flex-center-between" @click.stop>{{ $t('login.area') }}</li>
                  <li class="flex-center-between" :class="{ colorBtn: item.area == register.area }"
                      v-for="(item, index) in areaList" :key="index"
                      @click="register.area = item.area, dropdown = false">
                    <span v-if="$i18n.locale == 'zh-cn'">{{ item.cn }}</span>
                    <span v-else-if="$i18n.locale == 'zh-tw'">{{ item.tw }}</span>
                    <span v-else>{{ item.en }}</span>
                    <span>+{{ item.area }}</span>
                  </li>
                </ul>
              </div>
              <van-field class="input" v-model="register.captcha" clearable :placeholder="$t('login.tips_4')"
                         :rules="[{ required: true, message: $t('login.tips_4') }]">
                <template #button>
                  <span class="colorBtn" v-show="!isStart"
                        @click.stop="start(register.text, 'register', register.area)">{{ $t('login.tips_5') }}</span>
                  <van-count-down ref="countDown" v-show="isStart" @finish="finish" :time="60000" :auto-start="false"
                                  format="ss s" style="color:#2564F6;" />
                </template>
              </van-field>
              <van-field class="input" :type="checkPwd" clearable v-model="register.password"
                         :placeholder="$t('login.tips_3')" :rules="[{ required: true, message: $t('login.tips_3') }]">
                <template #button>
                  <div class="flex">
                    <van-icon @click="check(1)" v-show="!isCheck" name="closed-eye" size="18px"
                              style="cursor: pointer;" />
                    <van-icon @click="check(0)" v-show="isCheck" name="eye-o" size="18px" style="cursor: pointer;" />
                  </div>
                </template>
              </van-field>
              <van-field class="input" :type="checkPwd" clearable v-model="register.repassword"
                         :placeholder="$t('login.tips_2')" :rules="[{ required: true, message: $t('login.tips_2') }]">
                <template #button>
                  <div class="flex">
                    <van-icon @click="check(1)" v-show="!isCheck" name="closed-eye" size="18px"
                              style="cursor: pointer;" />
                    <van-icon @click="check(0)" v-show="isCheck" name="eye-o" size="18px" style="cursor: pointer;" />
                  </div>
                </template>
              </van-field>
              <van-button color='#2564F6' :disabled="isDisabled(register)" type="primary" native-type="submit" block>{{
                $t('login.title_2') }}</van-button>
            </van-form>
            <div class="tab flex-center-end">
              <span>{{ $t('login.tips_6') }}</span>
              <span style="color:#2564F6;" @click="type = 0, finish()">{{ $t('login.tips_7') }}</span>
            </div>
            <div class="text">{{ $t('login.text_4') }}
              <span class="colorBtn" @click="routerClick(1)">{{ $t('login.treaty_1') }}</span>{{ $t('login.text') }}
              <span class="colorBtn" @click="routerClick(0)">{{ $t('login.treaty_2') }}</span>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { LOGINAREA } from '../common/LOGINAREA'
import { message } from 'ant-design-vue'
export default {
  name:'LoginModal',
  data() {
    return {
      areaList:Object.freeze(LOGINAREA),
      isLoading:false,
      dropdown: false,
      type: 0,
      isCheck: false,
      isStart: false,
      checkPwd: 'password',
      area: '86',
      login: {
        text: '',
        pwd: ''
      },
      reset: {
        area: '86',
        type: 'mobile',
        text: '',
        code: '',
        pwd: '',
        newpassword: '',
      },
      register: {
        area: '86',
        type: 0,
        text: '',
        captcha: '',
        password: '',
        repassword: ''
      },
    }
  },
  computed: {
    ...mapState(['loginModalVisible','redirect']),
    ...mapGetters('user', ['isLogin']),
    formTitle() {
      const titles = {
        'login': 'title_1',
        'register': 'title_2',
        'resetpwd': 'title_3',
      };
      const key = this.type == 0 ? 'login' : this.type == 1 ? 'resetpwd' : 'register';
      const titleKey = titles[key]
      return this.$t(`login.${titleKey}`);
    },
  },
  methods: {
    ...mapActions('user', ['PasswordLogin', 'AuthTokenLogin']),
    ...mapMutations(['LOGIN_MODAL_VISIBLE','SET_REDIRECT','CLEAR_REDIRECT']),
    routerClick(type) {
      if (type) {
        this.$router.push({ name: 'Treaty' });
      } else {
        this.$router.push({ name: 'Privacy' });
      }
      this.handleCancel()
    },
    areaClick(item) {
      this.area = item
      this.dropdown = false
    },
    handleCancel() {
      Object.assign(this.$data, this.$options.data());
      this.LOGIN_MODAL_VISIBLE(false);
      this.isLoading = false
      const { name, meta:{requiresAuth} } = this.$route
      if(!name||requiresAuth&&!this.isLogin) this.$router.replace({ name: 'Home' })
      if(this.redirect) this.CLEAR_REDIRECT()
    },
    handleHasRedirect(){
      if(this.redirect){
        const { name } = this.$route
        if(this.redirect.name === name) return location.reload();
        this.$router.push({ ...this.redirect });
        this.CLEAR_REDIRECT()
      }
    },
    //忘记密码
    async handleReset(){
      const {type,area,text,code,pwd,newpassword} = this.reset
      if(pwd !== newpassword) return message.warning(this.$t('login.reset'));
      try {
        this.isLoading = true
        const params = {
          type,
          ...(type === 'mobile'&&{area}),
          ...(type === 'mobile'&&{mobile:text}),
          ...(type === 'email'&&{email:text}),
          captcha:code,
          newpassword,
        }
        const {msg} = await this.$http.post(this.APIURL.PUBLIC.RESETPWD,params,false)
        this.isLoading = false
        message.success(msg);
        this.type = 0
        Object.assign(this.$data, this.$options.data());
      } catch (error) {
        this.isLoading = false
      }
    },
    //登录
    async handleLogin() {
      try {
        this.isLoading = true
        const { subscribe } = this.$route.query
        const { text: account, pwd: password } = this.login
        await this.PasswordLogin({ account, password, ...(subscribe && { subscribe }) })
        this.LOGIN_MODAL_VISIBLE(false);
        Object.assign(this.$data, this.$options.data());
        this.isLoading = false
        this.handleHasRedirect()
      } catch (error) {
        this.isLoading = false
      }
    },
    //注册
    async handleRegister() {
      try {
        this.isLoading = true
        const { subscribe, spread } = this.$route.query
        const params = {
          ...this.register,
          ...(subscribe && { subscribe }),
          ...(spread && { spread })
        }
        const {data:{userinfo:{token}}} = await this.$http.post(this.APIURL.PUBLIC.REGISTER,params,false)
        await this.AuthTokenLogin(token)
        this.LOGIN_MODAL_VISIBLE(false);
        Object.assign(this.$data, this.$options.data());
        this.isLoading = false
        this.handleHasRedirect()
      } catch (error) {
        this.isLoading = false
      }
    },
    change(value) {
      let reg = /[a-zA-Z@]+/
      if (reg.test(value)) {
        if (this.type == 1) {
          this.reset.type = 'email'
          this.reset.area = ''
        } else if (this.type == 2) {
          this.register.type = 1
        }
      } else {
        if (this.type == 1) {
          this.reset.type = 'mobile'
          this.reset.area = this.area
        } else if (this.type == 2) {
          this.register.type = 0
        }
      }
    },
    start(text, type, area) {
      if (text) {
        this.$http.post(this.APIURL.PUBLIC.GETCODE,
          {
            text: text,
            ...(this.reset.type==='mobile'&&{area}),
            type: type,
          }).then(res => {
            if (res.code == '1') {
              this.isStart = true
              this.$refs.countDown.start();
            } else {
              message.warning(res.msg);
            }
          })
      }
    },
    finish() {
      this.isStart = false
      this.$refs.countDown.reset();
    },
    check(type) {
      if (type) {
        this.isCheck = true
        this.checkPwd = 'text'
      } else {
        this.isCheck = false
        this.checkPwd = 'password'
      }
    },
    isDisabled(obj) {
      for (const key in obj) {
        if (key == 'area') {
          continue
        }
        if (!String(obj[key])) {
          return true
        }
      }
      return false
    }
  },
}
</script>
<style lang="scss" scoped>
.dropdown {
  position: relative;

  .dropdown-menu {
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 0 10px 0 20px;
    box-shadow: 0px 2px 12px 0px rgba(48, 49, 51, 0.1);
    max-height: 300px;
    overflow-y: auto;
    display: block;
    position: absolute;
    left: 0;
    top: 110%;

    li {
      color: #333333;
      font-size: 14px;
      padding: 10px 0;
      cursor: pointer;
      border-bottom: 1px solid #D8D8D8;

      &:first-child {
        &:hover {
          color: #333;
        }
      }

      &:hover {
        color: $color;

      }
    }
  }
}

@media(max-width:768px) {
  .dropdown {
    .dropdown-menu {
      li {
        &:hover {
          color: #333333;
        }
      }
    }
  }
}
</style>