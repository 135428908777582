import APIURL from '../../api/APIURL'
import http from '../../api/http'
//用户模块
const INITIAL_INFO = {
  email: '',
  phone: '',
};
const state = {
  token: '',
  info: INITIAL_INFO,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_INFO: (state, info) => {
    state.info = info;
  },
};
const actions = {
  // 登录
  PasswordLogin({ dispatch, commit }, { account, password,subscribe }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data:{
            userinfo:{ token }
          }
        } = await http.post(APIURL.PUBLIC.LOGIN,{account,password,...(subscribe && { subscribe })},false)
        commit("SET_TOKEN", token);
        dispatch("GetInfo");
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  AuthTokenLogin({ dispatch, commit }, token){
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_TOKEN", token);
        dispatch("GetInfo");
        resolve()
      } catch (error) {
        reject()
      }
    });
  },
  Logout({ dispatch }){
    return new Promise(async (resolve, reject) => {
      try {
        await http.post(APIURL.PUBLIC.LOGOUT,{},false)
        dispatch("Reset");
        resolve();
      } catch (error) {
        // dispatch("Reset");
        reject(error);
      }
    });
  },
  Reset({ commit },isIntercept){
    commit("SET_TOKEN", '');
    commit("SET_INFO", INITIAL_INFO);
    if(isIntercept)commit("LOGIN_MODAL_VISIBLE",true,{root:true});
  },
  GetInfo({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data:{
            phone,
            email
          }
        } = await http.get(APIURL.PUBLIC.USER,{},false)
        const info = {
          phone,
          email
        }
        commit("SET_INFO", info);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
};

const getters = {
  isLogin: state => {
    return state.token !== '';
  },
};

export default {
  namespaced: true, //开启命名空间，用来映射子模块
  state,
  mutations, //如果开启了命名空间，就直接挂载到子模块上了，默认是挂载到全局的
  actions,
  getters,
};
