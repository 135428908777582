<template>
  <div id="nav-wrapper" ref="navbar">
    <div class="nav-container flex-center-between container">
      <div class="flex-center-start">
        <div class="logo">
          <img src="../assets/top_logo.png" @click="handleBackHome">
        </div>
        <div class="slogan" @click="handleBackHome">{{$t('common.slogan')}}</div>
        <div class="global-search-wrapper pc-search" v-show="isSearch">
          <a-input 
            class="search-input" 
            v-model="keyword" 
            @pressEnter="handleSearch" 
            :placeholder="$t('route.search_tips')"
            allowClear
           >
            <a-button
              @click="handleSearch"
              slot="suffix"
              class="search-btn flex"
              size="large"
              type="primary"
            >
              <a-icon type="search"/>
            </a-button>
          </a-input>
        </div>
      </div>
      <div class="navbar-wrapper flex-center-start">
        <div class="nav-list">
          <router-link class="link-pc" to="/">{{ $t("route.home") }}</router-link>
          <router-link class="link-pc" to="/mycards">{{ $t("route.my_cards") }}</router-link>
          <router-link class="link-pc" v-if="isLogin" to="/mine/user">{{ $t("route.mine") }}</router-link>
          <span class="link-pc" v-else @click="LOGIN_MODAL_VISIBLE(true)">{{ $t("route.login") }}</span>
          <van-popover v-if="isLogin"
            class="app-nav-popover"
            placement="bottom-end" 
            v-model="AppPopover" 
            trigger="click"
            :actions="AppLinks" 
            @select="onSelectHandler">
            <template #reference>
              <div class=" flex">
                <a class="link-app">
                  <a-icon type="align-center" style="font-size: 18px;"/>
                </a>
              </div>
            </template>
          </van-popover>
          <span class="flex link-app" v-else @click="LOGIN_MODAL_VISIBLE(true)">
            {{ $t("route.login") }}
          </span>
        </div>
        <div class="nav-locales">
          <span @click="onLangClick('zh-cn')" :class="{ 'locales-active': $i18n.locale == 'zh-cn' }">简</span>
          <a-divider type="vertical"/>
          <span @click="onLangClick('en')" :class="{ 'locales-active': $i18n.locale == 'en' }">EN</span>
        </div>
      </div>
    </div>
    <div class="global-search-wrapper app-search container" v-show="isSearch">
      <a-input 
        class="search-input" 
        v-model="keyword" 
        @pressEnter="handleSearch" 
        :placeholder="$t('route.search_tips')"
        allowClear
        >
        <a-button
          @click="handleSearch"
          slot="suffix"
          class="search-btn flex"
          size="large"
          type="primary"
        >
          <a-icon type="search"/>
        </a-button>
      </a-input>
    </div>
  </div>
</template>
<script>
import { mapActions,mapGetters,mapMutations } from 'vuex';
import { debounce } from "../utils/dbucTrtl"
export default {
  name:'NavigationBar',
  data() {
    return {
      keyword:'',
      AppPopover: false,
      commonLinks: [
        { text: this.$t('route.my_cards') ,url:'MyCards' },
        { text: this.$t('route.user') ,url:'User' },
        { text: this.$t('route.sim_order') ,url:'SimOrder'},
        { text: this.$t('route.flow_order') ,url:'FlowOrder'},
        { text: this.$t('route.address') ,url:'Address'},
        { text: this.$t('route.guide') ,url:'Guide'},
        { text: this.$t("route.logout"),url:'Logout' }
      ]
    }
  },
  computed: {
    ...mapGetters('user',['isLogin']),
    isSearch(){
      const { name,query:{iccid} } = this.$route
      return name === 'Home' || name === 'Search'&&!iccid
    },
    AppLinks() {
      const links = [...this.commonLinks];
      if (this.$route.name!=='Home') {
        links.splice(0, 0, { text: this.$t("route.home"), url: 'Home' });
      }
      return links;
    },
  },
  mounted(){
    this.debouncedResizeHandler = debounce(this.resetHeightHandler, 1000);
    window.addEventListener("resize", this.debouncedResizeHandler);
    this.resetHeightHandler()
  },
  methods:{
    ...mapActions('user',['Logout']),
    ...mapMutations(['LOGIN_MODAL_VISIBLE','SET_NAVBAR_HEIGHT']),
    resetHeightHandler(){
      setTimeout(() => {
        const { clientHeight } = this.$refs.navbar
        this.SET_NAVBAR_HEIGHT(clientHeight)
      });
    },
    handleBackHome(){
      this.$router.push({ name: 'Home' });
    },
    handleSearch(){
      if(!this.keyword) return
      this.$router.push({ name: 'Search', query: { keyword:this.keyword}});
    },
    async onLogoutHandler(){
      try {
        await this.$dialog.confirm({
          title: this.$t('login.logout_title'),
          message: this.$t('login.logout_text'),
          confirmButtonText: this.$t('login.logout_ok'),
          cancelButtonText: this.$t('common.cancel'),
        })
        await this.Logout()
        const { requiresAuth } = this.$route.meta
        if(requiresAuth) this.$router.push({ name: 'Home' });
      } catch (error) {
        
      }
    },
    onSelectHandler(name) {
      const { url } = name
      if (url === 'Logout') return this.onLogoutHandler()
      this.$router.push({ name:url });
    },
    onLangClick(lang){
      if(lang === this.$i18n.locale) return
      const langMap = { 'zh-cn': 'zh-cn', 'en': 'en' };
      if (this.$route.query.lang) { 
        let query = Object.assign({}, this.$route.query);
        query.lang = lang;
        this.$router.push({ query }); 
      }
      localStorage.setItem('sim_lang', langMap[lang]);
      this.$i18n.locale = localStorage.getItem('sim_lang'); 
      location.reload();
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .global-search-wrapper{
  .search-input{
    transition: all 0.5s ease;
    .ant-input{
      height: 40px;
      padding-left: 16px;
      padding-right: 82px;
      border-radius: 20px;
      border-color: #999999;
      &:focus{
        box-shadow: none;
        border-color: $color;
      }
    }
    .ant-input-clear-icon{
      font-size: 14px;
      transform: translateX(-42%);
    }
  }
}
/deep/ .pc-search{
  display: block;
  margin-left: 20px;
  .search-input{
    @mixin search-input-active {
      width: 280px;
      white-space: initial;
      overflow: initial;
      text-overflow: initial;
      border-color: $color;
    }
    .ant-input{
      width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover{
        @include search-input-active
      }
      &:focus{
        @include search-input-active
      }
    }
    &:hover{
      .ant-input{
        @include search-input-active
      }
    }
  }
}
.slogan{
  cursor: pointer;
  display: none;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 2px solid #999999;
  @media(min-width:1200px) {
    display: block;
  }
}
#nav-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  flex-direction: column;
  user-select: none;
  font-size: 14px;
  min-height: 80px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 1px solid #F2F3F6;
  .nav-container{
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 80px;
  }
  .logo {
    width: 110px;
    cursor: pointer;
  }
  .app-search{
    display: none;
  }
  .nav-list {
    .link-app {
      display: none;
    }
  }
  .nav-locales {
    text-align: center;
    span {
      color: #999;
      user-select: none;
      cursor: pointer;
    }
    .locales-active {
      color: #333;
      font-weight: 600;
    }
  }
  .link-pc {
    cursor: pointer;
    color: #999;
    position: relative;
    margin-right: 30px;
    transition: all .3s cubic-bezier(0.4,0.8,0.74,1);
    &::after {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 0);
      content: '';
      width: 0;
      height: 2px;
      border-radius: 12px;
      background-color: $color;
      transition: all .4s cubic-bezier(0.4,0.8,0.74,1);
    }
    &:hover{
      color: $color;
      &::after{
        width: 20px;
      }
    }
    &.router-link-exact-active {
      color: $color;
      font-weight: 600;
      &::after {
        width: 30px;
      }
    }
  }
}
@media(max-width:992px) {
  #nav-wrapper {
    height: auto;
    justify-content: center;
    .nav-container{
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 0px;
      min-height: initial;
    }
    .logo{
      @media(max-width:320px) {
        width: 110px;
      }
    }
    .navbar-wrapper {
      flex-direction: row-reverse;
    }
    .pc-search{
      display: none;
    }
    .app-search{
      display: block;
      padding: 10px 16px;
    }
    .nav-list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .link-pc {
        display: none;
      }
      .link-app{
        display: flex;
        color: #666;
        font-size: 14px;
        margin-left: 14px;

      }
    }
  }
}
</style>