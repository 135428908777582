export default {
  route: {
    'home': '网卡',
    'mine': '个人中心',
    'topup': '充值套餐',
    'search': '搜索',
    'my_cards': '我的卡',
    'search_tips': '搜索目的地，如：香港',
    'login': '登录',
    'logout': '退出登录',
    'sim_order':'购卡订单',
    'flow_order':'充值订单',
    'address':'收货地址',
    'guide':'使用帮助',
    'user':'个人信息',
  },
  common:{
    'coupon':'优惠券',
    'card_fee':'含环球旅游上网卡费',
    'each':'每个',
    'cancel':'取消',
    'ok':'确认',
    'all':'全部套餐',
    'hot':'热门地区',
    'area':'覆盖地区',
    'area_global':'进入官网 · 玩转地球',
    'other':'其他套餐',
    'slogan':'让信号一直在您身边',
    'price':'价格',
    'from':'低至 ',
    'tup-op_tips':'请输入要充值的漫游卡号',
    'title_1':'套餐规格：',
    'title_2':'套餐天数：',
    'title_3':'套餐说明',
    'title_4':'购买数量',
    'title_5':'充值卡号',
    'title_6':'请选择收货方式',
    'title_7':'请选择收货地址',
    'title_8':'请添加收货地址',
    'title_9':'请选择配送快递',
    'title_10':'请选择您要充值的漫游卡套餐',
    'btn_1':'立即购买',
    'btn_2':'立即充值',
    'btn_3':'添加新地址',
    'pay_1':'确认订单',
    'pay_2':'订单信息',
    'pay_3':'最晚使用日期：',
    'pay_4':'请选择支付方式',
    'pay_5':'应付金额',
    'pay_6':'确认付款',
    'pay_7':'订单金额：',
    'pay_8':'订单号：',
    'pay_9':'订单提交成功，请您尽快完成付款！',
    'pay_10':'取消支付',
    'pay_11':'我已完成支付',
    'pay_12':'查询订单中，请稍候',
    'pay_13':'合计：',
    'pay_14':'邮寄',
    'pay_15':'自取',
    'search_1':'搜索关键字：',
    'search_2':'搜索结果',
    'search_3':'暂无此套餐',
    'search_4':'请输入关键字',
    'check_order':'查看订单',
    'pay_again': '重新支付',
    'back_home':'回到首页',
    'result_success':'支付成功',
    'result_fail':'支付失败',
    'result_tips':'订单号: {text} 订单配置需要1-3分钟，请耐心等待。',
  },
  mycards:{
    'tag_1':'可重复使用',
    'tag_2':'插卡即用',
    'tag_3':'支持Google Map',
    'bind_title':'环球旅游上网卡',
    'bind_desc':'查询流量使用情况 便捷充值',
    'bind_tips':'请输入20位环球旅游上网卡号',
    'bind_btn':'添加',
    'add_title':'添加新卡',
    'tab_1': '我的卡',
    'tab_2': '历史套餐',
    'unbind':'解除绑定',
    'topup':'充值套餐',
    'handle_again':'再次购买',
    'handle_stop':'终止使用',
    'handle_use':'优先使用',
    'last_time':'最近充值套餐',
    'empty':'暂无套餐',
    'expired':'已使用/已过期套餐',
    'data_use':'已使用',
    'use_tips':'数据显示有一定延迟，请以实际使用情况为准',
    'unbind_text':'解除绑定',
    'unbind_tips':'后则在卡列表不再显示此卡信息，可以输入卡号重新绑定。',
    'stop':'终止使用',
    'stop_tips_1':'是否终止使用',
    'stop_tips_2':'套餐，终止后将不能继续使用该网络。',
    'use':'优先使用',
    'use_tips_1':'优先使用',
    'use_tips_2':'流量后，',
    'use_tips_3':'将自动终止，无法再次使用。',
    'use_tips_4':'抵达目的地后，打开数据漫游，',
    'use_tips_5':'流量将自动激活。',
    'cancel':'再想想',
    'confirm':'确定',
    'iccid':'充值卡号：',
    'destination':'目的地：',
    'topup_title':'选择规格',
    'topup_subtitle':'请选择您要充值的套餐规格',
    'topup_confirm':'充值确认',
    'next':'下一步',
    'fold':'收起',
    'unfold':'查看更多套餐',
  },
  reviews:{
    'title':'万游宝广受全球超1,000,000人次用户信赖',
    'add_up':'累计 5,000 多条',
    'reviews':'评论',
  },
  footer:{
    'name':'优游宝科技（深圳）有限公司',
    'hk_name':'香港总部',
    'hk_addess':'香港九龙长沙湾大南西街1018号东方国际大厦11楼1103室',
    'am_name':'澳门分部',
    'am_addess':'澳门宋玉生广场336-342号诚丰商业中心17楼F/G室',
    'sz_name':'深圳分部',
    'sz_addess':'深圳市南山区科伟路3号东方科技大厦2515室',
    'tw_name':'台湾分部',
    'tw_addess':'台中市北屯区平德里永定2街35号1楼',
    'link_title':'友情链接',
    'link_yyb':'优游宝官网',
    'link_wyb':'万游宝商城',
    'link_esim':'TraveleSIM商城',
    'contact_us':'客服咨询',
    'commerce_contact':'合作咨询',
    'scan_app':'扫码下载APP',
    'scan_wechat':'扫码关注公众号',
    'download':'下载TraveMate APP',
    'rights':'版权所有',
  },
  invite:{
    'mycode':'我的邀请码',
    'code':'邀请码',
    'copy':'复制',
    'copy_success':'复制成功',
    'copy_fail':'复制失败',
    'share':'立即分享',
    'records':'邀请记录',
    'use':'去使用',
    'no_record':'暂无记录，快去分享你的邀请码吧~',
    'know':'我知道了',
    'now':'立即体验',
    'invited':'共邀请好友',
    'coupons':'共获得优惠券（张）',
    'rules':'活动规则',
    'text_1':'分享邀请码，领取优惠券',
    'text_2':'分享邀请码',
    'text_3':'复制邀请码，推荐好友注册体验万游宝。',
    'text_4':'好友注册',
    'text_5':'好友使用您的邀请码并完成注册，即得新人礼包。',
    'text_6':'获赠优惠券',
    'text_7':'好友凭邀请码完成注册后，您将获赠￥5优惠券。',
    'text_8':'分享文案已复制',
    'text_9':'文案已复制，快去分享给你的好友，让信号一直在您身边~',
    'tips':'请打开微信搜索万游宝公众号，于“流量商城”内进行优惠领取。'
  },
  http: {
    'timeout':'服务器响应超时，请刷新当前页',
    'fail': '数据获取失败',
    'server_fail':'连接服务器失败',
    'err': '请求接口失败',
    '400': '请求错误',
    '404': '请求错误,未找到该资源',
    '500':'服务器端出错',
    '502':'网络错误',
    '504':'网络超时',
  },
  login: {
    'reset':'输入密码不一致',
    'logout_title':'退出登录',
    'logout_text':'确认退出登录？',
    'logout_ok':'退出登录',
    'title_1':'登录',
    'title_2':'注册',
    'title_3':'忘记密码',
    'tips_1':'请输入手机号码/邮箱地址',
    'tips_2':'请再次确认密码',
    'tips_3':'请输入密码',
    'tips_4':'请输入验证码',
    'tips_5':'获取验证码',
    'tips_6':'已有账号，',
    'tips_7':'马上登录',
    'tips_8':'请输入新的账号密码',
    'tips_9':'请再次确认新的账号密码',
    'tips_10':'返回登录',
    'area':'请选择区号',
    'treaty_1':'《用户协议》',
    'treaty_2':'《隐私政策》',
    'text_1':'输入手机号码/邮箱地址，创建漫游卡账号。点击“登录”，即代表您同意我们的',
    'text_2':'为了您的账号安全，请验证邮箱',
    'text_3':'为了您的账号安全，请验证手机',
    'text_4':'输入手机号码/邮箱地址，注册漫游卡账号。点击“注册”，即代表您同意我们的',
    'text':'和',
    'tab_1':'或手机',
    'tab_2':'或邮箱',
  },
  mine:{
    'flow_3':'复制成功',
    'flow_4':'复制失败',
    'empty':'暂无数据',
    'tips':'*订单包含漫游卡和流量套餐，如需了解更多套餐详细信息，请下载',
    'app':'万游宝App',
    'tab_1':'全部',
    'tab_2':'待付款',
    'tab_3':'待发货',
    'tab_4':'待收货',
    'tab_5':'待自取',
    'tab_6':'已完成',
    'tab_7':'已付款',
    'tab_8':'已取消',
    'time':'剩余付款时间：',
    'user_1':'账号：',
    'user_2':'登录密码：',
    'user_3':'已设置',
    'user_4':'修改',
    'user_5':'绑定邮箱',
    'user_6':'已绑定',
    'user_7':'未绑定',
    'user_8':'修改',
    'user_9':'绑定',
    'user_10':'绑定手机',
    'user_11':'请输入旧密码',
    'user_12':'请输入新密码',
    'user_13':'请输入邮箱地址',
    'user_14':'请输入手机号码',
    'order_1':'地区套餐',
    'order_2':'下单时间',
    'order_3':'实付款',
    'order_4':'订单状态',
    'order_5':'支付方式',
    'order_6':'操作',
    'order_7':'订单编号：',
    'order_8':'立即支付',
    'order_9':'实付：',
    'order_10':'暂无订单',
    'order_11':'收货方式',
    'order_12':'物流详情',
    'order_13':'确认收货',
    'order_14':'快递单号：',
    'order_15':'快递公司：',
    'order_16':'物流信息：',
    'order_17':'物流详情',
    'order_18':'复制',
    'copy_1':'复制成功',
    'copy_2':'复制失败',
    'flow_1':'请在{msg}前使用',
    'flow_2':'于{msg}取消',
    'add_1':'收件人',
    'add_2':'地区',
    'add_3':'请选择所在地区',
    'add_4':'设为默认地址',
    'add_5':'详细地址',
    'add_6':'请输入详细地址',
    'add_7':'邮政编码',
    'add_8':'请输入邮政编码',
    'add_9':'编辑地址',
    'add_10':'请选择',
    'add_11':'地址',
    'add_12':'默认地址',
    'add_13':'姓名：',
    'add_14':'电话：',
    'add_15':'邮编：',
    'add_16':'删除',
    'add_17':'编辑',
    'add_18':'默认',
    'add_19':'编辑地址',
    'add_tips':"不能包含特殊字符"
  },
  user:{
    'privacy_title':'万游宝隐私政策',
    'privacy':`
      <p>欢迎您使用优游宝科技有限公司（以下称“万游宝”或“我们”）提供的服务，注册地址为深圳市前海深港合作湾一路1号A栋201室。</p>
      <p>万游宝遵守适用的数据保护及隐私法律和规例（“适用法律”）。在适用法律允许的最大范围内，及受用户在适用法律要求下的有效同意所规限，用户在使用万游宝软件、服务、网站或任何其他提供万游宝服务的第三方平台，即表示同意及接受本隐私政策。我们可能不时更新本隐私政策以反映我们的软件或商品的改变和用户意见，或适用法律的改变。如本隐私政策或对于我们將如何使用用户的个人资料之方式有重大改变时，我们会在实施该等改变之前以明显的方式刊登有关通知或直接向用户发出通知。我们鼓励用户定期细阅读本隐私政策，以得知我们正如何收集，使用及保护用户的资料。</p>
			<p class="subtitle">1. 我们收集的个人信息</p>
			<p>我们可能会不时向用户收集个人资料或其他信息，以便向用户提供我们的产品或服务。我们所收集的信息包括但不限于：</p>
			<p>(a)用户在注册时所提供的资料，或是稍后显示在万游宝软件用户端、万游宝线上搜寻目录以及在万游宝软件的个人中心页面的万游宝个人资料。其中可能包括用户的万游宝头像、手机号码等，以及用户同意提供的所有其他资料；</p>
			<p>(b)身份识别资料（如姓名、地址、电话号码、手机号码）；</p>
			<p>(c)电子识别资料（如IP地址、cookies）；</p>
      <p>(d)相机权限：当您使用万游宝扫一扫功能添加漫游卡时，会以弹窗形式向您请求相机权限，相机权限是敏感权限，拒绝提供仅会使你无法使用扫一扫功能添加上网卡（也可选择手动输入进行添加），不会影响其他功能的使用。</p>
      <p>(e)应用列表信息：为了实现【第三方社交平台登录】功能，万游宝使用了MobTech的【ShareSDK第三方登录】产品。当为您检测并实现ID同步及合并链路的服务时，会获取应用列表信息。</p>
			<p>(f)有关用户使用我们的软件、服务、产品或网页互动的资讯（包括电脑、用户端资讯、错误报告、页面浏览统计资料、浏览器类型和使用统计资料)；</p>
			<p>(g)流量数据（为传送通讯或计费而处理的数据）；</p>
			<p>(h)付款详情，包括收件人信息及支付方式；</p>
			<p>(i)联系人详情，包括联系人姓名、电话号码等。</p>
			<p>此外，用户的线上时段资料可能会间接地以统计和匿名的方式被收集。</p>
			<p>如用户未满18岁时，用户需在家长或监护人的同意和监督下提供个人资料。</p>
			<p>本隐私政策不适用于通过下列方式所收集的数据：通过万游宝服务而接入第三方服务（包括任何第三方网站）；或通过在万游宝宣传服务的其他公司和机构所收集的资料。</p>
			<p class="subtitle">2. 用户个人信息的使用</p>
			<p>我们可能会不时向用户收集个人资料或其他信息，以便向用户提供我们的产品或服务。我们所收集的信息包括但不限于：</p>
			<p>(a) 接受、处理或传送经由我们的网站产生的订单，开立发票给用户或通知用户订单状态；</p>
			<p>(b) 为用户提供其他服务（如我们收集资讯时所述）；</p>
			<p>(c) 为用户提供客户紧急救援及其它疑难排解服务；</p>
			<p>(d) 比较资讯的准确性；</p>
			<p>(e) 通知用户关于服务更新与故障的资讯；</p>
			<p>(f) 解决争议；</p>
			<p>(g) 验证用户的身份；</p>
			<p>(h) 改善我们的软体、服务或网站；</p>
			<p>(i) 筹划与执行我们的营销或推广活动、优惠和类似活动；</p>
			<p>(j) 通过广告支持，免费提供某些软件、功能及网站；</p>
			<p>(k) 针对故障和服务问题与用户联络，传送相关讯息；</p>
			<p>(l) 以电子邮件、软件或短信进行通知，使用户知悉关于使用万游宝软件、产品或网站的最终求偿资讯，包括但不限于侵犯第三方权利的侵权求偿；</p>
			<p>(m) 使用短信或万游宝消息去通知用户万游宝软件、服务或网站的最新信息，及告知用户我们的相关推广。</p>
			<p class="subtitle">3. 用户个人信息的披露及转移</p>
			<p>除了以下所述之外，在未获得用户的有效及明确许可下，万游宝不会销售、出租、交易或转让任何个人流量数据或通讯内容，除非万游宝在适用法律或管辖机构的命令下有义务作出上述行为。</p>
			<p>万游宝可能披露个人信息以回应法律规定，行使我们的法律权利或于索偿中抗辩，以保护万游宝的利益，打击诈欺行为及执行我们的政策或保护用户的合法权利、财产或安全。</p>
			<p class="subtitle">4. 用户的个人信息安全及保存</p>
			<p>我们会在用户是我们客户期间或用户已不再使用我们的服务之后储存用户的个人信息，但此仅为有必要的或者因应法律要求。我们将严格执行相关法律下的适用义务和例外规定，采取适当的组织性和技术性措施保护向其提供或所收集的个人资料和流量资料。用户的个人资料和流量资料仅供经许可员工或第三方存取，并且只会在必要时才保存至达到履行其收集的原来目的或直接相关的目的时，个人资料和流量资料被保存以符合任何适用法律或合同义务除外。</p>
			<p class="subtitle">5. 查阅或改正数据</p>
			<p>用户有权查阅用户的个人信息，并在相关的情况下加以改正。</p>
			<p>除非法律要求保留或因为合法的商业目的，我们将尽合理努力满足删除个人资讯的要求。</p>
			<p>根据现行法律，我们保留对处理任何查阅资料请求而收取合理费用的权利。</p>
			<p class="subtitle">6. 手机通知服务</p>
			<p>当用户的手机使用万游宝时，我们可能会在用户没有执行或没有使用万游宝应用的情况下，利用该手机的通知服务让用户得知资讯。这些服务项目可能由第三方提供。例如，传送到Apple装置的讯息会使用Apple的紧急通知服务。</p>
			<p>通知服务可能收到关于来电者、讯息发送者和讯息内容的资讯以提供服务，并可能根据服务提供者的条款和细则及隐私权原则来使用这些资讯。 (Apple为AppleInc.在美国及其他国家的注册商标。)</p>
			<p class="subtitle">7. 第三方服务</p>
			<p>7.1 为了实现【 第三方社交平台登录 】功能，我们使用了 MobTech 的【 ShareSDK 第三方登录 】产品。</p>
			<p>此产品的隐私策略条款：<a href="http://www.mob.com/about/policy">http://www.mob.com/about/policy</a></p>
			<p>7.2 我们的产品集成【友盟+SDK】，【友盟+SDK】需要收集您的设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。</p>
			<p>此产品的隐私权政策链接：<a href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a></p>
			<p class="subtitle">8. 联系我们</p>
			<p>如用户对本隐私政策有任何查询，请在万游宝应用内联系我们客服。</p>`,
    'treaty_title':'用户注册协议',
    'treaty':`
        <p>万游宝提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您完整阅读并选择是否接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</p>
        <p>本协议约定万游宝与用户之间关于万游宝软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。</p>
        <p class="subtitle">一、 账号注册</p>
        <p>1、 用户在使用本服务前需要注册一个万游宝账号。万游宝账号应当使用手机号码/邮箱注册，请用户使用尚未注册的手机号码或邮箱账号注册。万游宝可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，无须事先通知用户。</p>
        <p>2、 万游宝系基于地理位置的移动互联网产品，用户注册时应当授权万游宝公开及使用其方可成功注册万游宝账号。故用户完成注册即表明用户同意万游宝提取、公开及使用用户的地理位置信息。</p>
        <p>3、 鉴于万游宝账号的绑定注册方式，万游宝在用户注册时将自动提取用户手机号码及手机设备识别码等信息。</p>
        <p>4、 在用户注册及使用本服务时，万游宝需要搜集能识别用户身份的个人信息以便万游宝可以在必要时联系用户，或为用户提供更好的使用体验。</p>
        <p class="subtitle">二、 服务内容 </p>
        <p>本服务的具体内容由万游宝根据实际情况提供，万游宝可以对其提供的服务予以变更，服务变更将在更新版本时一并提供。</p>
        <p class="subtitle">三、 用户个人隐私信息保护</p>
        <p>1、 用户在注册账号或使用本服务的过程中，可能需要填写或提交一些必要的信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。</p>
        <p>2、 个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在万游宝服务器端的基本记录信息、个人隐私信息范围外的其他普通信息，以及用户同意公开的上述隐私信息。</p>
        <p>3、 万游宝尊重用户个人隐私信息的私有性，确保个人隐私信息安全，防止在本服务中收集的用户个人隐私信息泄露、毁损或丢失。在发生前述情形或者万游宝发现存在发生前述情形的可能时，将及时采取补救措施。</p>
        <p>4、 万游宝未经用户同意不向任何第三方公开、透露用户个人隐私信息，但以下特定情形除外：</p>
        <p>（1）万游宝根据法律法规规定或有权机关的指示提供用户的个人隐私信息；</p>
        <p>（2）由于用户与他人共享注册账户，由此导致的任何个人信息的泄露，或其他非万游宝原因导致的个人隐私信息的泄露；</p>
        <p>（3）用户自行向第三方公开其个人隐私信息；</p>
        <p>（4）用户与万游宝及合作单位之间就用户个人隐私信息的使用公开达成约定，万游宝因此向合作单位公开用户隐私信息；</p>
        <p>（5）为防止冒用他人的身份进行注册，本软件将通过短信方式来验证手机号码，注册或邀请所产生的短信费用将由运营商收取；</p>
        <p>（6）任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。</p>
        <p>5、用户同意万游宝可在以下事项中使用用户的个人信息：</p>
        <p>（1）万游宝向用户及时发送重要通知，如软件更新、本协议条款的变更；</p>
        <p>（2）万游宝由内部进行审计、数据分析和研究等，以改进万游宝的产品、服务和用户之间的沟通；</p>
        <p>（3）依本协议约定，万游宝管理、审查用户信息及进行处理措施；</p>
        <p>（4）适用法律法规规定的其他事项。</p>
        <p>除上述事项外，如未取得用户事先同意，万游宝不会将用户个人隐私信息适用于任何其他用途。</p>
        <p>6、万游宝重视对未成年人个人隐私信息的保护。万游宝将依赖用户提供的个人信息判断用户是否为未成年人。任何18岁以下的未成年人注册账号或使用本服务应事先取得家长或其法定监护人（以下简称“监护人”）的同意。除根据法律法规的规定及有权机关的指示披露外，万游宝不会使用或向任何第三方透露未成年人的个人隐私信息。</p>
        <p>7、用户确认：地理位置信息为非个人隐私信息，用户成功注册万游宝账号视为确认授权万游宝自由提取、公开及使用用户的地理信息。用户地理位置信息将作为用户公开资料之一，由万游宝向其他用户公开。</p>
        <p>8、为了改善万游宝的技术和服务，向用户提供更好地服务体验，万游宝会自行收集使用或向第三方提供用户的非个人隐私信息。</p>
        <p class="subtitle">四、 内容规范</p>
        <p>1、 本条所述内容是指用户使用本服务过程中所制作、上传、复制、发布、传播的任何内容，包括但不限于账号头像、名称等注册信息及认证资料，以及其他使用账号或本服务所产生的内容。</p>
        <p>2、 用户不得利用万游宝账号或本服务制作、上传、复制、发布、传播如下法律、法规和政策禁止的内容：</p>
        <p>（1）反对宪法所确定的基本原则的；</p>
        <p>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>（3）损害国家荣誉和利益的；</p>
        <p>（4）煽动民族仇恨、民族歧视、破坏民族团结的；</p>
        <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>（9）含有法律、行政法规禁止的其他内容的信息。</p>
        <p>3、 用户不得利用万游宝账号或本服务制作、上传、复制、发布、传播如下干扰万游宝正常运营，以及侵犯其他用户或第三方合法权益的内容：</p>
        <p>（1）含有任何性或性暗示的；</p>
        <p>（2）含有辱骂、恐吓、威胁内容的；</p>
        <p>（3）含有骚扰、垃圾广告、恶意信息、诱骗信息的；</p>
        <p>（4）涉及他人隐私、个人信息或资料的；</p>
        <p>（5）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
        <p>（6）含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权内容的信息。</p>
        <p class="subtitle">五、 使用规则</p>
        <p>1、 用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表万游宝的观点、立场或政策，万游宝对此不承担任何责任。</p>
        <p>2、 用户不得利用万游宝账号或本服务进行如下行为：</p>
        <p>（1）提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；</p>
        <p>（2）强制、诱导其他用户关注、点击链接页面或分享信息的；</p>
        <p>（3）虚构事实、隐瞒真相以误导、欺骗他人的；</p>
        <p>（4）利用技术手段批量建立虚假账号的；</p>
        <p>（5）利用万游宝账号或本服务从事任何违法犯罪活动的；</p>
        <p>（6）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否具有商业目的；</p>
        <p>（7）其他违反法律法规规定、侵犯其他用户合法权益、干扰万游宝正常运营或万游宝未明示授权的行为。</p>
        <p>3、 用户须对利用万游宝账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等安全负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与万游宝无关。如因此给万游宝或第三方造成损害的，用户应当依法予以赔偿。</p>
        <p>4、 万游宝提供的服务中可能包括广告、用户在使用过程中显示万游宝和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对该广告信息进行的交易负责，对用户因该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，万游宝不承担任何责任。</p>
        <p class="subtitle">六、 账号管理</p>
        <p>1、万游宝账号的所有权归万游宝所有，用户完成申请注册手续后，获得万游宝账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。万游宝因经营需要，有权回收用户的账号。</p>
        <p>2、用户可以更改、删除万游宝账户上的个人资料、注册信息及传送内容等，但需注意，删除有关信息的同时也会删除用户储存在系统中的文字和图片。用户需承担该风险。</p>
        <p>3、用户有责任妥善保管注册账号信息的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册账号行为承担法律责任。用户同意在任何情况下不使用其他用户的账号。在用户怀疑他人使用其账号时，用户同意立即通知万游宝。</p>
        <p>4、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，万游宝有权依据协议中断或终止对违约用户的万游宝账号提供服务。同时，万游宝保留在任何时候收回账号的权利。</p>
        <p>5、如用户注册账号后一年不登录，万游宝可以收回该账号，以免造成资源浪费，由此造成的不利后果由用户自行承担。</p>
        <p class="subtitle">七、 数据储存</p>
        <p>1、万游宝不虚构、不自行上传内容，所有数据均由用户主动上传。万游宝仅承担存储者的角色。</p>
        <p>2、本软件涉及到WiFi联网和Internet服务，可能会受到各个环节不稳定因素的影响。因此服务存在因上述不可抗力、病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、GSM网络、互联网络、通信线路原因等造成服务中断或不能满足用户要求的风险。开通服务的用户须承担以上风险，服务方对服务的及时性、安全性、准确性不做担保，对因此导致的用户通信不畅，不承担任何责任。</p>
        <p>3、万游宝可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。</p>
        <p>4、如用户停止使用本服务或本服务终止，万游宝可以从服务器上永久地删除用户的数据。本服务停止、终止后，万游宝没有义务向用户返还任何数据。</p>
        <p class="subtitle">八、 用户注意事项</p>
        <p>1、用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户同意赔偿本公司与合作公司、关联公司，并使之免受损害。</p>
        <p>2、本软件涉及到WiFi联网和Internet服务，可能会受到各个环节不稳定因素的影响。因此服务存在因上述不可抗力、病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、GSM网络、互联网络、通信线路原因等造成服务中断或不能满足用户要求的风险。开通服务的用户须承担以上风险，服务方对服务的及时性、安全性、准确性不做担保，对因此导致的用户通信不畅，不承担任何责任。</p>
        <p>3、对于因不可抗力而造成的服务器的死机、网络的中断，以及软件升级时的服务暂停等，进而对本服务用户造成的损失，服务方不承担任何责任。</p>
        <p>4、万游宝提供断网情况下的自动连接，用户注册使用，则视为已同意接受此服务，由此产生的数据流量费用由用户自行承担。</p>
        <p>5、用户应提供和分享自有WiFi热点信息，或保证分享的其他热点（包括但不限于密码和地理位置等）在热点提供者的许可范围内，并保证分享的所有WiFi热点的信息安全。由于违反本条而引发的问题，万游宝不承担任何责任。</p>
        <p>6、用户应当确保将由万游宝获取的热点信息用于恰当的用途，严禁任何非法或违背相关准则规定的行为。由此引发的问题，与万游宝无关。</p>
        <p>7、热点提供者有权利要求从万游宝的数据库中剔除由其提供的热点信息。万游宝将按照法律规定予以相应处理。</p>
        <p class="subtitle">九、 法律及争议解决</p>
        <p>1、 本协议适用中华人民共和国法律。</p>
        <p>2、 因本协议引起的或与本协议有关的任何争议，各方应友好协商解决；协商不成则任何一方均可将有关争议提交至深圳仲裁委员会并按照其届时有效的仲裁规则仲裁；仲裁裁决是终局的，对各方均有约束力</p>
        <p class="subtitle">十、 其他条款</p>
        <p>1、 如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为删除，但本协议的其余条款仍应有效并且有约束力。</p>
        <p>2、 万游宝有权随时根据有关法律、法规的变化以及公司经营状况和经营策略的调整等修改本协议。修改后的协议会随附于新版本软件发布。当发生有关争议时，以最新的协议文本为准。如果不同意改动的内容，用户可以自行删除本软件。如果用户继续使用本软件，则视为您接受本协议的变动。</p>
      <p>3、 万游宝在法律允许的最大范围内对本协议拥有解释权与修改权。`
  }
}